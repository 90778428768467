import React from "react";

import Select from "react-select";
import moment from "moment";

import useMap from "Hooks/useMap";
import useProjects from "Hooks/useProjects";
import { transform } from "ol/proj";

const selectComponent = ({ style, className, selectOpen, isLayerMenuOpen }) => {
    const { map } = useMap();
    const { projects, selectedProjectForViewOnMap, setSelectedProjectForViewOnMap } = useProjects();

    const handleSelectChange = (selected) => {
        setSelectedProjectForViewOnMap(selected);

        let view = map.getView();
        view.setCenter(transform(selected.coordinates, "EPSG:4326", "EPSG:3857"));
        view.setZoom(16);

        selectOpen(false);
        isLayerMenuOpen(false);
    };

    return (
        <>
            <Select
                styles={style}
                className={className}
                options={projects?.results?.map((project) => {
                    return {
                        value: project,
                        //label: `${project.name.toUpperCase()}, ${moment(project.project_date).format("DD.MM.YYYY.")}`,
                        label: `${project.name.toUpperCase()}`,
                        coordinates: project.geom.coordinates,
                        id: project.id,
                    };
                })}
                type="text"
                placeholder="Odaberi projekt"
                isSearchable
                onChange={handleSelectChange}
                value={selectedProjectForViewOnMap}
                components={{ IndicatorSeparator: () => null }}
                isDisabled={selectedProjectForViewOnMap?.id ? false : true}
            />
        </>
    );
};

export default selectComponent;
