/* eslint-disable no-unused-vars */
import React, { createContext, useState, useEffect, useRef } from "react";

import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import * as olExtent from "ol/extent";
import { transform } from "ol/proj";
import { fromLonLat } from "ol/proj";
import { transformExtent } from "ol/proj";

import useMap from "Hooks/useMap";
import useAxiosPrivate from "Hooks/useAxiosPrivate";
import useProjects from "Hooks/useProjects";
import { View } from "ol";

const ProjectObjectsContext = createContext({});

export const ProjectObjectsProvider = ({ children }) => {
    const [objectsForSelectedProject, setObjectsForSelectedProject] = useState(null);
    const [displayedVectorLayers, setDisplayedVectorLayers] = useState([]);
    const [colorStyle, setColorStyle] = useState([]);
    // Not the  best way to do this
    const [render, setRender] = useState(false);

    const { selectedProjectForViewOnMap } = useProjects();
    const { map } = useMap();
    const axiosPrivate = useAxiosPrivate();

    // Check if vector layer with same class name exsists
    const thisLayerExists = (layer_name) => {
        let layers = map.getLayers().getArray();

        for (let i = 0; i < layers.length; i++) {
            let objectClassName = layers[i].get("habitatClass");

            if (objectClassName === layer_name) {
                return layers[i];
            }
        }
    };

    // Style features
    const featureStyle = (feature) => {
        let fillColorOption = [
            {
                value: "Morska staništa", // modro plava (tailwind blue 500) + y
                colorBackground: "#3b82f63a",
                colorLine: "#60a5fa",
            },
            {
                value: "Obalna staništa", // // narančasta (orange 500) + y -+
                colorBackground: "#f973163a",
                colorLine: "#fb923c",
            },
            {
                value: "Kopnene površinske vode", // svjietlo plava cyan 500 +
                colorBackground: "#06b6d43a",
                colorLine: "#22d3ee",
            },
            {
                value: "Bare i močvare", // zelena teal 500 + y
                colorBackground: "#14532d3d",
                colorLine: "#16a34a",
            },
            {
                value: "Travnjaci i zemljišta kojima dominiraju travke, mahovine ili lišajevi", // svijetlije žuta (tailwind yellow 600) + y -
                colorBackground: "#ca8a042a",
                colorLine: "#eab308",
            },
            {
                value: "Vrištine, šikare i tundra", // tamnije žuta (tailwind amber 400) + y -
                colorBackground: "#fbbf242a",
                colorLine: "#fcd34d",
            },
            {
                value: "Šume i ostalo šumovito zemljište", // zelena (green 500) + y
                colorBackground: "#1665343a",
                colorLine: "#22c55e",
            },
            {
                value: "Kopnena neobrasla ili rijetko obrasla staništa", // smeđe (yellow 900) + y
                colorBackground: "#713f123a",
                colorLine: "#854d0e80",
            },
            {
                value: "Redovito ili nedavno kultivirana poljoprivredna, hortikulturna i domaća staništa", // zelena (lime 500) + y
                colorBackground: "#84cc163a",
                colorLine: "#a3e635",
            },
            {
                value: "Izgrađena, industrijska i druga umjetna staništa", // siva + y
                colorBackground: "#64748b3a",
                colorLine: "#94a3b8",
            },
            {
                value: "Kompleksi staništa", // ljubičasta + y
                colorBackground: "#a855f73a",
                colorLine: "#c084fc",
            },
        ];

        const findUsageColor = fillColorOption.find((option) => {
            if (option.value === feature) {
                return option;
            }
        });

        setColorStyle((current) => [...current, findUsageColor]);

        return [
            new Style({
                stroke: new Stroke({
                    color: findUsageColor.colorLine,
                    width: 2.5,
                }),
                fill: new Fill({
                    color: findUsageColor.colorBackground,
                }),
            }),
        ];
    };

    useEffect(() => {
        // Getting objects data from API for selected project
        const getObjectData = async () => {
            setDisplayedVectorLayers([]);
            const { data } = await axiosPrivate.get("/api/habitats/objects", {
                params: {
                    project: selectedProjectForViewOnMap.id,
                },
            });

            setObjectsForSelectedProject(data);
        };
        if (selectedProjectForViewOnMap?.id) {
            getObjectData();
        }
    }, [selectedProjectForViewOnMap, render]);

    useEffect(() => {
        if (objectsForSelectedProject?.count > 0) {
            for (let i = 0; i < objectsForSelectedProject?.results?.length; i++) {
                let object = objectsForSelectedProject.results[i];
                let objectHabitatClass = object.habitat_class_name;

                /* Check if vector layer with same class name exsists */
                let layer_ = thisLayerExists(objectHabitatClass);

                /* Preparing GeoJSON file */
                let feature = new GeoJSON().readFeatures({
                    type: "FeatureCollection",
                    features: [object.geom],
                });

                // Transform from source to map's projection
                feature.forEach((feature) => {
                    let projectedGeom = feature.getGeometry().transform("EPSG:4326", "EPSG:3857");
                    feature.setGeometry(projectedGeom);
                });

                /* Creating new vector layers - if vector with class name doesn't exist create new one, if exist just add new vector to that specific vector source */
                if (!layer_) {
                    let layer = new VectorLayer({
                        habitatClass: object.habitat_class_name,
                        object: object,
                        source: new VectorSource({
                            features: feature,
                        }),
                        style: featureStyle(object.habitat_class_name),
                    });
                    setDisplayedVectorLayers((current) => [...current, layer]);

                    layer.set("altitudeMode", "clampToGround"); // 3D clamp to ground

                    map.addLayer(layer);
                }
                if (layer_) {
                    layer_.getSource().addFeatures(feature);
                }
            }

            // Extent map so that all features are viewed in first view of map after polygon selection
            let vectorLayers = map.getLayers().getArray();
            let extent = olExtent.createEmpty();
            let classVectorLayers = [];
            for (let i = 0; i < vectorLayers.length; i++) {
                let existingLayer = vectorLayers[i];
                if (vectorLayers[i].get("habitatClass")) {
                    classVectorLayers.push(existingLayer);
                    olExtent.extend(extent, existingLayer.getSource().getExtent());
                } else {
                    /* existingLayer.setExtent("bounds"); */
                }
            }
            if (classVectorLayers.length) {
                map.getView().fit(extent, map.getSize());
            }
            if (!classVectorLayers.length) {
                let view = map.getView();

                view.setCenter(transform(selectedProjectForViewOnMap.coordinates, "EPSG:4326", "EPSG:3857"));
                view.setZoom(16);
            }

            const fitToExtent = document.querySelector(".ol-zoom-extent");

            fitToExtent.addEventListener("click", (e) => {
                if (classVectorLayers.length) {
                    map.getView().fit(extent, map.getSize());
                }
                if (!classVectorLayers.length) {
                    map.setView(
                        new View({
                            center: fromLonLat([16.450145, 44.515856]),
                            zoom: 7,
                            extent: transformExtent([10.0, 41.0, 22.0, 48.0], "EPSG:4326", "EPSG:3857"),
                        })
                    );
                }
            });
        }

        return () => {
            /* Clearing everything after selecting new project */
            if (map) {
                setColorStyle([]);
                let layers = map.getLayers().getArray();
                objectsForSelectedProject.results.forEach((object) => {
                    layers.forEach((layer) => {
                        if (layer.get("habitatClass") === object.habitat_class_name) {
                            layer.getSource().clear();
                            map.removeLayer(layer);
                        }
                    });
                });
            }
        };
    }, [objectsForSelectedProject]);
    return (
        <ProjectObjectsContext.Provider
            value={{
                objectsForSelectedProject,
                setObjectsForSelectedProject,
                displayedVectorLayers,
                colorStyle,
                setRender,
            }}
        >
            {children}
        </ProjectObjectsContext.Provider>
    );
};

export default ProjectObjectsContext;
