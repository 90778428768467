import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../Style/cookie.css";

const CookieComponent = () => {
    const descriptionMessage =
        "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.";

    const [isCookieOpen] = useState(false);
    const [cookieClass, setCookieClass] = useState("wrapper-cookie");

    const cookieSetting = () => {
        console.log("test");
    };

    const acceptAllCookies = () => {
        const cookieExpirationDate = setCookieExpirationDateOneYearInFuture();

        //define which cookie will be saved
        const userNameTest = "SomeName";
        document.cookie = `username=${userNameTest}; isActive="true"; expires=${cookieExpirationDate}; path=/; SameSite=None; Secure`;

        closeCookieElement();
    };

    const setCookieExpirationDateOneYearInFuture = () => {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        return date.toUTCString();
    };

    const closeCookieElement = () => {
        setCookieClass((previousState) => {
            return `${previousState} wrapper-cookie-hide`;
        });
    };

    return (
        <>
            {isCookieOpen && (
                <div className={cookieClass}>
                    <div className="cookie-description font-inter">{descriptionMessage}</div>
                    <div className="cookie-btns md:flex md:flex-col md:gap-[15px]">
                        <Link to={"/cookie"}>
                            <button
                                className="btn-secondary w-36 bg-transparent"
                                onClick={cookieSetting}
                            >
                                Cookie Settings
                            </button>
                        </Link>
                        <button
                            className="btn-primary w-44 font-semibold"
                            onClick={acceptAllCookies}
                        >
                            Accept All Cookies
                        </button>
                    </div>
                    <div>
                        <button
                            className="cookie-close"
                            onClick={closeCookieElement}
                        ></button>
                    </div>
                </div>
            )}
        </>
    );
};
export default CookieComponent;
