import React, { useState, useEffect, useRef } from "react";
import {
    MeasureIcon,
    PolygonMeasureIcon,
    LineMeasureIcon,
    LineMeasureIconHeader,
    PolygonMeasureIconHeader,
    LineMeasureIconInactive,
    PolygonMeasureIconInactive,
} from "Media/Icons/HeaderComponentIcons";
import { CloseNotificationSuccess } from "Media/Icons/NotificationCloseIcon";

import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";

/*Imports for Measure Tool*/
import Draw from "ol/interaction/Draw";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";
import useMap from "Hooks/useMap";
import useVectorSource from "Hooks/useVectorSource";

import("../Style/measure.css");

const MeasureComponent = ({ active3d }) => {
    const [measureOpen, setMeasureOpen] = useState(false);
    const [selectedInteraction, setSelectedInteraction] = useState("");
    const [isMeasureActive, setIsMeasureActive] = useState(false);
    const { source } = useVectorSource();
    const { map } = useMap();
    const [vectorLayer, setVectorLayer] = useState();

    function setMeasureMenu() {
        setMeasureOpen(!measureOpen);
    }

    // PC measure
    const handleDistanceMeasure = () => {
        setSelectedInteraction("length");
        setMeasureOpen(false);
        setIsMeasureActive(true);
    };
    const handleAreaMeasure = () => {
        setSelectedInteraction("area");
        setMeasureOpen(false);
        setIsMeasureActive(true);
    };

    // Mobile measure
    const handleDistanceMeasureMobile = () => {
        setIsMeasureActive(true);
        setSelectedInteraction("length");
    };
    const handleAreaMeasureMobile = () => {
        setIsMeasureActive(true);
        setSelectedInteraction("area");
    };

    // MEASURE

    // Currently drawn feature
    let sketch;

    // The help tooltip element
    let helpTooltipElement;

    //Overlay to show the help message
    let helpTooltip;

    // The measure tooltip element.
    let measureTooltipElement;

    // Overlay to show the measurement.
    let measureTooltip;

    // Message to show when the user is drawing a polygon.
    const continuePolygonMsg = "Click to continue drawing the polygon";

    // Message to show when the user is drawing a line.
    const continueLineMsg = "Click to continue drawing the line";

    let vector;

    // Helper functions

    // Format length output
    const formatLength = (line) => {
        const length = getLength(line);
        let output;
        if (length > 100) {
            output = Math.round((length / 1000) * 100) / 100 + " " + "km";
        } else {
            output = Math.round(length * 100) / 100 + " " + "m";
        }
        return output;
    };

    // Format area output
    const formatArea = (polygon) => {
        const area = getArea(polygon);
        let output;
        if (area > 10000) {
            output = Math.round((area / 1000000) * 100) / 100 + " " + "km<sup>2</sup>";
        } else {
            output = Math.round(area * 100) / 100 + " " + "m<sup>2</sup>";
        }
        return output;
    };

    // Create popup for help message
    const pointerMoveHandler = (evt) => {
        if (selectedInteraction) {
            let coordinate = evt.coordinate;

            if (evt.dragging) {
                return;
            }

            helpMsg = "Click to start drawing";

            if (sketch) {
                const geom = sketch.getGeometry();
                if (geom instanceof Polygon) {
                    helpMsg = continuePolygonMsg;
                } else if (geom instanceof LineString) {
                    helpMsg = continueLineMsg;
                }
            }

            helpTooltipElement.innerHTML = helpMsg;
            helpTooltipElement.className = "ol-tooltip md:hidden";
            helpTooltip.setPosition(coordinate);
        }
    };
    /* if (map) {
        map.on("pointermove", pointerMoveHandler);
    } */

    // Creates a new help tooltip
    const createHelpTooltip = () => {
        helpTooltipElement = document.createElement("div");
        helpTooltipElement.className = "absolute";
        helpTooltip = new Overlay({
            element: helpTooltipElement,
            offset: [15, 0],
            positioning: "center-left",
            id: "helpTooltip",
        });
        if (map) {
            map.addOverlay(helpTooltip);
        }
    };

    // Creates a new measure tooltip
    const createMeasureTooltip = () => {
        measureTooltipElement = document.createElement("div");
        measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
        measureTooltip = new Overlay({
            element: measureTooltipElement,
            offset: [0, -15],
            positioning: "bottom-center",
            stopEvent: false,
            insertFirst: false,
            id: "measureTooltip",
        });
        if (map) {
            map.addOverlay(measureTooltip);
        }
    };

    useEffect(() => {
        if (!isMeasureActive) {
            vector = new VectorLayer({
                source: source,
                style: {
                    "fill-color": "rgba(255, 255, 255, 0.2)",
                    "stroke-color": "#ffcc33",
                    "stroke-width": 2,
                    "circle-radius": 7,
                    "circle-fill-color": "#ffcc33",
                },
            });
            if (map) {
                map.addLayer(vector);
            }
            vector.set("name", "flag_vectorLayer");
            setVectorLayer(vector);
        }

        return () => {
            if (isMeasureActive && map) {
                // TODO - destroy html elements?
                measureTooltipElement.remove();
                helpTooltipElement.remove();
                let overlays = map.getOverlays().getArray();
                overlays = overlays.filter((overlay) => overlay.id !== "popup");
                overlays.forEach((overlay) => {
                    map.removeOverlay(overlay);
                });
            }
        };
    }, [map, isMeasureActive]);

    let draw;
    let helpMsg;
    let listener;
    let drawStart, drawEnd, pointerMove;

    useEffect(() => {
        // Adding new interaction, it changes if new interaction was selected (Polygon, Line)
        const addInteraction = () => {
            let type;
            if (selectedInteraction == "area") {
                type = "Polygon";
            } else if (selectedInteraction == "length") {
                type = "LineString";
            } else {
                return;
            }

            if (type) {
                draw = new Draw({
                    source: source,
                    type: type,
                    stopClick: true,
                    style: new Style({
                        fill: new Fill({
                            color: "rgba(255, 255, 255, 0.2)",
                        }),
                        stroke: new Stroke({
                            color: "rgba(0, 0, 0, 0.5)",
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: "rgba(0, 0, 0, 0.2)",
                            }),
                            fill: new Fill({
                                color: "rgba(255, 255, 255, 0.2)",
                            }),
                        }),
                    }),
                });
            }

            if (map && type) {
                map.addInteraction(draw);
            }

            createMeasureTooltip();
            createHelpTooltip();

            drawStart = draw.on("drawstart", (evt) => {
                sketch = evt.feature;

                let tooltipCoord = evt.coordinate;

                listener = sketch.getGeometry().on("change", (evt) => {
                    const geom = evt.target;
                    let output;
                    if (geom instanceof Polygon) {
                        output = formatArea(geom);
                        tooltipCoord = geom.getInteriorPoint().getCoordinates();
                    } else if (geom instanceof LineString) {
                        output = formatLength(geom);
                        tooltipCoord = geom.getLastCoordinate();
                    }
                    measureTooltipElement.innerHTML = output;
                    measureTooltip.setPosition(tooltipCoord);
                });
            });

            drawEnd = draw.on("drawend", () => {
                measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
                measureTooltip.setOffset([0, -7]);
                // unset sketch
                sketch = null;
                // unset tooltip so that a new one can be created
                measureTooltipElement = null;
                createMeasureTooltip();
                unByKey(listener);
            });

            pointerMove = map.on("pointermove", pointerMoveHandler);
        };

        addInteraction();

        // Let user change the geometry type. Removing old interaction and adding new one which was selected
        return () => {
            if (selectedInteraction) {
                map.removeInteraction(draw);
                helpTooltipElement.remove();
                unByKey(drawStart);
                unByKey(drawEnd);
                unByKey(pointerMove);
            }
        };
    }, [selectedInteraction]);

    const cancelMeasureMode = () => {
        setIsMeasureActive(false);
        setSelectedInteraction(null);

        // Delete vector source
        vectorLayer.getSource().clear();

        // Delete vector layers
        /* map.getLayers()
            .getArray()
            .filter((layer) => layer.get("name") == "flag_vectorLayer")
            .forEach((layer) => map.removeLayer(layer));
        setIsMeasureActive(false); */
    };

    return (
        <>
            <button
                title="Mjerenje"
                className={`${
                    isMeasureActive ? "bg-white-100 text-green-500" : "bg-green-500"
                } group w-[44px] md:bg-transparent h-[44px] flex justify-center items-center rounded-[50%] hover:bg-white-100 md:hidden disabled:hover:bg-green-500 ${
                    active3d?.active ? "button-disabled" : ""
                }`}
                onClick={setMeasureMenu}
                disabled={active3d?.active}
            >
                {!isMeasureActive ? (
                    <MeasureIcon />
                ) : selectedInteraction === "length" ? (
                    <LineMeasureIconHeader />
                ) : (
                    <PolygonMeasureIconHeader />
                )}
            </button>

            <button
                title="Mjerenje dužine"
                className={`${
                    isMeasureActive && selectedInteraction === "length" ? "md:bg-green-500" : "md:bg-transparent"
                } hidden md:flex  md:w-[44px] md:h-[44px] md:justify-center md:items-center md:rounded-lg `}
                onClick={handleDistanceMeasureMobile}
            >
                <LineMeasureIconHeader
                    isMeasureActive={isMeasureActive}
                    selectedInteraction={selectedInteraction}
                />
            </button>

            <button
                title="Mjerenje površine"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "md:bg-green-500" : ""
                } hidden md:flex md:group md:w-[44px] md:h-[44px] md:justify-center md:items-center md:rounded-lg`}
                onClick={handleAreaMeasureMobile}
            >
                <PolygonMeasureIconHeader
                    isMeasureActive={isMeasureActive}
                    selectedInteraction={selectedInteraction}
                />
            </button>

            {/* <div
                className="absolute hidden"
                ref={helpTooltipElement}
            ></div> */}

            {selectedInteraction && (
                <div
                    className="cursor-pointer w-[170px] h-[40px] text-m bg-orange-100 text-white-100 z-[3005] opened-state rounded-[20px] fixed top-20 right-4 flex items-center hover:opacity-90 font-medium md:flex md:top-16 md:right-[3px] md:w-[105px] md:z-30"
                    onClick={cancelMeasureMode}
                >
                    <div className="ml-[13px] mr-2.5">
                        <CloseNotificationSuccess />
                    </div>
                    <div className="flex items-center">
                        <span className="md:hidden">Isključi mjerenje</span>
                        <span className="hidden md:block">Isključi</span>
                    </div>
                </div>
            )}

            {measureOpen ? (
                <div className="font-inter absolute flex flex-col gap-y-0.5 top-[72px] ml-[118px] text-gray-400 rounded-[20px]">
                    <button
                        title="Mjerenje dužine"
                        onClick={handleDistanceMeasure}
                        className={`${
                            selectedInteraction === "area"
                                ? "bg-white-100 text-gray-500 hover:text-gray-400"
                                : "bg-white-100"
                        } group h-11 w-[215px] rounded-l-[20px] rounded-r-[20px] border-green-600 border-[1px] flex items-center shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]`}
                    >
                        <div
                            className={`${
                                selectedInteraction === "area" ? "bg-white-100" : "text-white-100 bg-green-500"
                            } w-11 h-11 rounded-[50%] mr-3 border-[1px] border-green-600 items-center flex justify-center ml-[-1px]`}
                        >
                            {selectedInteraction === "area" ? <LineMeasureIconInactive /> : <LineMeasureIcon />}
                        </div>
                        Izmjeri dužinu
                    </button>
                    <button
                        title="Mjerenje površine"
                        onClick={handleAreaMeasure}
                        className={`${
                            selectedInteraction === "length"
                                ? "group bg-white-100 text-gray-500 hover:text-gray-400"
                                : "bg-white-100"
                        } group h-11 w-[215px] rounded-l-[19px] rounded-r-[20px] border-green-600 border-[1px] flex items-center shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]`}
                    >
                        <div
                            className={`${
                                selectedInteraction === "length" ? "bg-white-100" : "text-white-100 bg-green-500"
                            } w-11 h-11 rounded-[50%] mr-3 border-[1px] border-green-600 items-center flex justify-center ml-[-1px]`}
                        >
                            {selectedInteraction === "length" ? <PolygonMeasureIconInactive /> : <PolygonMeasureIcon />}
                        </div>
                        Izmjeri površinu
                    </button>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default MeasureComponent;
