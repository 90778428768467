import useLogout from "Hooks/useLogout";
import useNotifications from "Hooks/useNotifications";
import React from "react";

export const RegisteredUserMenu = () => {
    const logout = useLogout();
    const { setMessage } = useNotifications();

    const handleLogOut = async () => {
        try {
            logout();
        } catch (error) {
            setMessage(error, "error");
        }
    };

    return (
        <div>
            <div className="font-inter w-[270px] h-[65px] absolute flex justify-center items-center bg-white-100 top-[72px] right-2.5 rounded-[10px] md:w-[150px] md:h-[100px] md:top-16 md:right-2.5 md:flex md:flex-col-reverse md:items-center md:justify-center md:content-center shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]">
                <a
                    href="/admin"
                    target="_blank"
                    className="btn-secondary"
                >
                    Admin sučelje{" "}
                </a>
                <button
                    className="btn-primary"
                    onClick={handleLogOut}
                >
                    Odjava
                </button>
            </div>
        </div>
    );
};
