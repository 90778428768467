import React from "react";

export const PolygonDrawIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="44"
            fill="none"
        >
            <circle
                cx="22"
                cy="22"
                r="21.5"
                fill="#197058"
                stroke="#B2CFC7"
            />
            <circle
                cx="22"
                cy="22"
                r="11"
                fill="#fff"
            />
            <path
                stroke="#197058"
                strokeLinecap="round"
                d="M22.5 16.5v10M17.5 21.5h10"
            />
        </svg>
    );
};

export const AddImage = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
        >
            <circle
                cx="10"
                cy="10"
                r="10"
                fill="#197058"
            />
            <path
                stroke="#FEFEFE"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M10 15.714V10m0 0V4.286M10 10h5.714M10 10H4.286"
            />
        </svg>
    );
};

export const RemoveImage = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none"
        >
            <path
                stroke="#545454"
                strokeLinecap="round"
                strokeOpacity=".3"
                strokeWidth="1.5"
                d="M1.536 8.607 5.07 5.07m0 0 3.536-3.535M5.07 5.07l3.536 3.536M5.07 5.07 1.536 1.536"
            />
        </svg>
    );
};

export const CloseX = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
        >
            <path
                stroke="#3F3F46"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m6 6 12 12M6 18 18 6 6 18Z"
            />
        </svg>
    );
};
