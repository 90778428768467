import { AuthProvider } from "./AuthProvider";
import { combineContexts } from "Utils/combineContexts";
import { NotificationsProvider } from "./NotificationProvider";
import { MapProvider } from "./MapProvider";
import { VectorSourceProvider } from "./VectorSourceProvider";
import { ProjectsProvider } from "./ProjectsProvider";
import { ProjectObjectsProvider } from "./ProjectObjectsProvider";

/* Add all high level contexts here. First in array is rendered highest in component tree, and so on... */
const providers = [
    NotificationsProvider,
    AuthProvider,
    MapProvider,
    VectorSourceProvider,
    ProjectsProvider,
    ProjectObjectsProvider,
]; // Add new context here
export const AppContextProvider = combineContexts(...providers);
