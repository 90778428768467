import React, { useState } from "react";

import { InfoIcon } from "Media/Icons/HeaderComponentIcons";
import InfoModal from "./InfoModal";

const InfoIconToolbar = () => {
    const [infoModalOpen, setInfoModalOpen] = useState(false);
    return (
        <>
            {infoModalOpen && <InfoModal handleCloseButton={setInfoModalOpen} />}
            <button
                title="Info"
                className="group w-[44px] h-[44px] flex justify-center items-center bg-green-500 rounded-[50%] hover:text-green-100 hover:bg-white-100 md:rounded-lg md:hover:bg-green-500 md:bg-transparent"
                onClick={() => setInfoModalOpen((prev) => !prev)}
            >
                <InfoIcon />
            </button>
        </>
    );
};

export default InfoIconToolbar;
