import React, { useState } from "react";

import { Search } from "../Media/Icons/HeaderComponentIcons";

import selectStyleMobile from "Utils/selectStyleMobile";
import SelectComponent from "./SelectComponent";
import "../Style/availableProjects.css";

const MobileAvailableProjectsSearch = () => {
    const [projectsSearchOpen, setProjectsSearchOpen] = useState(false);

    const handleOnProjectSearchClick = () => {
        setProjectsSearchOpen((prev) => !prev);
    };

    return (
        <div
            className={`${
                projectsSearchOpen ? "rounded-l-lg" : "rounded-lg"
            } md:absolute md:bg-white-100 font-inter md:left-[3px] md:right-3 w-full md:top-16 md:w-[50px] md:bg-opacity-90 md:flex items-center flex-col hidden md:bg-opacity-10"
            `}
        >
            <button
                className={`${
                    projectsSearchOpen ? "md:rounded-l-lg md:bg-green-100" : "md:rounded-lg md:bg-opacity-10"
                } w-[50px] h-[50px] flex justify-center items-center md:hover:fill-white-100 md:bg-white-100`}
                onClick={handleOnProjectSearchClick}
            >
                <Search isSearchOpen={projectsSearchOpen} />
            </button>
            {projectsSearchOpen && (
                <div className="md:fixed md:left-[52px] h-[50px] bg-white-100 flex items-center md:right-[5px] rounded-r-3xl px-2.5">
                    <SelectComponent
                        selectOpen={setProjectsSearchOpen}
                        style={selectStyleMobile}
                        className={"w-[250px] xl:w-[250px] lg:w-[45vw] md:w-full"}
                    />
                </div>
            )}
        </div>
    );
};

export default MobileAvailableProjectsSearch;
