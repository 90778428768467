import React from "react";

import {
    MinusIcon,
    PanIcon,
    PlusIcon,
    RulerIcon,
    LocationIcon,
    DofOsmIcon,
    Icon3D,
    CompareIcon,
} from "Media/Icons/InfoModalIcons";
import CloseIcon from "Media/Icons/CloseIcon";

const InfoModal = ({ handleCloseButton }) => {
    return (
        <div className="font-inter fixed top-1/2 left-1/2 rounded-[10px] -translate-x-1/2 -translate-y-1/2 z-[2000] bg-white-100 flex flex-col items-center px-[80px] w-[900px] text-black lg:w-[700px] lg:h-[600px] lg:overflow-y-auto scrollbar md:w-full md:h-full md:left-0 md:top-0 md:right-0 md:-translate-x-0 md:-translate-y-0 md:overflow-x-hidden md:rounded-none md:z-[10000] shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]">
            <h1 className="text-[40px] mt-6 text-green-100">HABI GIS</h1>
            <div className="mb-[33px] flex items-center flex-col md:flex-col md:gap-4 mt-[34px]">
                <div>
                    WEB GIS pregednik kreiran je u svrhu lakšeg pregledavanja i pretraživanja, dohvaćanja dostupnih
                    informacija te vizualizacije stanišnih tipova i pratećih podloga. Funkcionalnosti aplikacije su
                    sljedeće:
                </div>
                <div className="mt-6 mb-6">
                    <div className="flex items-center">
                        <div className="w-[150px] flex">
                            <div className="mr-4">
                                <PlusIcon />
                            </div>
                            <div className="mr-4">
                                <MinusIcon />
                            </div>
                            <div>
                                <PanIcon />
                            </div>
                        </div>
                        <div className="w-full">
                            standardni alati za manipulaciju kartografskim podlogama (zoom in/out, pan, fiksna mjerila,
                            uključivanje/isključivanje slojeva itd.)
                        </div>
                    </div>
                    <div className="flex items-center mt-6">
                        <div className="w-[150px] flex">
                            <RulerIcon />
                        </div>
                        <div className="w-full">računanja duljina i površina,</div>
                    </div>
                    <div className="flex items-center mt-6">
                        <div className="w-[150px] flex">
                            <LocationIcon />
                        </div>
                        <div className="w-full">geolociranje trenutnog položaja,</div>
                    </div>
                    <div className="flex items-center mt-6">
                        <div className="w-[150px] flex">
                            <DofOsmIcon />
                        </div>
                        <div className="w-full">
                            prikaz kartografske podloge s hipsometrijskom skalom boja različitih staništa prema EUNIS
                            kodovima,
                            <br />
                            prostorne podloge javnih servisa DGU (DOF5), Open Street Map (OSM)
                        </div>
                    </div>
                    <div className="flex items-center mt-6">
                        <div className="w-[150px] flex">
                            <Icon3D />
                        </div>
                        <div className="w-full">mogućnost 3D prikaza, navigacije i dohvata atributnih podataka,</div>
                    </div>
                    <div className="flex items-center mt-6">
                        <div className="w-[150px] flex">
                            <CompareIcon />
                        </div>
                        <div className="w-full">mogućnost prevlačenja slojeva (eng. layer swipe).</div>
                    </div>
                </div>
                <button
                    className="btn-secondary w-[200px] hidden lg:block md:block"
                    onClick={() => handleCloseButton(false)}
                >
                    Zatvori
                </button>
            </div>
            <div
                className="absolute right-5 top-6 cursor-pointer lg:hidden md:hidden"
                onClick={() => handleCloseButton(false)}
            >
                <CloseIcon />
            </div>
        </div>
    );
};

export default InfoModal;
