import { useEffect } from "react";
import useLogout from "Hooks/useLogout";
import { useNavigate } from "react-router-dom";

const Logout = () => {
    const logout = useLogout();
    const navigate = useNavigate();

    useEffect(() => {
        const logoutAndRedirect = async () => {
            await logout();

            navigate("/", { state: { from: "/login" }, replace: true });
        };

        logoutAndRedirect();
    }, []);

    return;
};

export default Logout;
