import React from "react";

import { CompareIcon } from "Media/Icons/HeaderComponentIcons";
import InfoIconToolbar from "./InfoIconToolbar";
import MeasureComponent from "./MeasureComponent";
import MobileAvailableProjectsSearch from "./MobileAvailableProjectsSearch";
import CompareComponent from "./CompareComponent";

const MobileSettingsMenu = () => {
    return (
        <>
            <div>
                <MobileAvailableProjectsSearch />
            </div>
            <div
                className={
                    "md:absolute md:left-[3px] origin-center md:top-[120px] md:w-[50px] md:bg-white-100 md:bg-opacity-90 md:flex items-center flex-col hidden rounded-lg pt-1 pb-3.5"
                }
            >
                {/* <button
                    title="Usporedi"
                    className="w-[44px] h-[44px] rounded-lg flex justify-center items-center btns-color hover:bg-green-500"
                >
                    <CompareIcon />
                </button> */}
                <div>
                    <CompareComponent />
                </div>
                <div>
                    <MeasureComponent />
                </div>
                <div>
                    <InfoIconToolbar />
                </div>
            </div>
        </>
    );
};

export default MobileSettingsMenu;
