import React from "react";
import "ol-layerswitcher/dist/ol-layerswitcher.css";
import "Style/layerSwitcher.css";

import LayerSwitcher from "ol-layerswitcher";
import useMap from "Hooks/useMap";
import { useEffect, useState } from "react";
import { useRef } from "react";
import TileLayer from "ol/layer/Tile";

export default function BaseMapControl() {
    const { map } = useMap();
    const container = useRef();
    const [activeBaseMap, setActiveBaseMap] = useState({ DOF: true, OSM: false });

    useEffect(() => {
        let layerswitcher;
        if (map) {
            layerswitcher = new LayerSwitcher({
                // reverse: true,
                // activationMode: "click", - aktivacija na klik, ne na hover
                target: container.current,
            });

            map.addControl(layerswitcher);
        }

        return () => {
            if (map) {
                map.removeControl(layerswitcher);
            }
        };
    }, [map]);

    const handleBaseChange = () => {
        let layers = map.getLayers();
        layers.forEach((element) => {
            let data = element.getProperties();
            if (data.type === "base") {
                if (data.title === "OSM") {
                    if (data.visible) {
                        setActiveBaseMap({ DOF: false, OSM: true });
                    } else {
                        setActiveBaseMap({ DOF: true, OSM: false });
                    }
                }
            }
        });
    };

    return (
        <>
            {activeBaseMap && (
                <div
                    ref={container}
                    className={`${activeBaseMap.DOF ? "dof-bg" : "osm-bg"}`}
                    onClick={handleBaseChange}
                ></div>
            )}
        </>
    );
}
