import React from "react";

import BackArrow from "Media/Icons/BackArrow";
import { Link } from "react-router-dom";

const CookiePage = () => {
    return (
        <div className="font-inter h-screen leading-tight bg-login-bg bg-no-repeat bg-cover">
            <div className="bg-green-100 h-20 text-white-100 flex items-center">
                <Link to={"/"}>
                    <button className="ml-12 flex items-center group hover:text-green-200">
                        <BackArrow />
                        <span className="ml-2">Početna</span>
                    </button>
                </Link>
            </div>
            <div className="fixed w-full max-h-screen top-[80px] bottom-0 bg-white-200 text-gray-300 flex justify-center overflow-auto">
                <div className="md:mt-0 my-8 text-gray-400 font-normal bg-white-100 md:h-full md:w-full h-[600px] w-[595px] px-[55px] py-[55px] rounded-[20px] md:rounded-none border-2 md:border-0 border-green-100">
                    <div>
                        <h1 className="font-medium text-[28px]">Cookie Settings</h1>
                        <p className="mt-1 text-base text-gray-100">
                            We use cookies, some of them are essential, others are optional.
                        </p>
                        <div className="mt-2.5 bg-white-300 h-[2px]"></div>
                    </div>
                    <div className="mt-7">
                        <h2 className="font-base text-[26px]">Stricly Necessary</h2>
                        <p className="text-base mt-1 text-gray-100">
                            These cookies are necessary for the website and can not be deactivated
                        </p>
                    </div>
                    <div className="mt-7">
                        <h2 className="font-base text-[26px]">Marketing & Analytics</h2>
                        <p className="text-base mt-1 text-gray-100">
                            These cookies can be set by our advertising partners through our website.
                        </p>
                    </div>
                    <div className="mt-7">
                        <h2 className="font-base text-[26px]">Preferences</h2>
                        <p className="text-base mt-1 text-gray-100">
                            To individualize your content, we use tools that personalize your web experieence
                        </p>
                    </div>
                    <div className="flex justify-end mt-16">
                        <button className="btn-secondary h-11 w-[138px] md:mr-1">Save Settings</button>
                        <button className="btn-primary h-11 w-[188px] font-medium text-base">Accept all cookies</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiePage;
