import React from "react";

export const LayerMenuIcon = () => {
    return (
        <svg
            width="36"
            height="36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                clipPath="url(#a)"
                fill="#006046"
            >
                <path d="M18 20.25c-.149 0-.296-.035-.43-.1l-15-7.09a1 1 0 0 1 0-1.81l15-7.09a1 1 0 0 1 .85 0l15 7.09a1 1 0 0 1 0 1.81l-15 7.09a1.001 1.001 0 0 1-.42.1ZM5.34 12.16l12.66 6 12.66-6L18 6.18 5.34 12.16Z" />
                <path d="M18 26.16c-.149 0-.296-.035-.43-.1L2.57 19a1 1 0 1 1 .85-1.81L18 24.06l14.57-6.89a1.01 1.01 0 1 1 .86 1.83l-15 7.09a1.002 1.002 0 0 1-.43.07Z" />
                <path d="M18 32.07c-.149 0-.296-.035-.43-.1l-15-7.09a1 1 0 0 1 .85-1.81L18 30l14.57-6.89a.999.999 0 0 1 1.172 1.58.999.999 0 0 1-.322.23L18.43 32a1.001 1.001 0 0 1-.43.07Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        d="M0 0h36v36H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const LayerMenuCloseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m6 6 12 12M6 18 18 6 6 18Z"
                stroke="#FEFEFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
