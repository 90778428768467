/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
import React, { useState, useRef, useEffect } from "react";

import "ol/ol.css";
import "../Style/map.css";
import "../Style/layerMenu.css";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Map, Tile, View } from "ol";
import { fromLonLat } from "ol/proj";
import { boundingExtent } from "ol/extent";
import { transformExtent } from "ol/proj";
import { ZoomToExtent, defaults as defaultControls, ScaleLine } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { OSM, TileWMS, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import Geolocation from "ol/Geolocation";

import PositionIcon from "../Media/Icons/PositionIcon";
import HomeIcon from "../Media/Icons/HomeIcon";
import { LayerMenuIcon } from "Media/Icons/LayerMenuIcons";

import rasterLayer from "../Utils/enums";
import HeaderComponent from "Components/HeaderComponent";
import CookieComponent from "Components/Cookie/CookieComponent";
import Notification from "Components/Notification";
import LayerMenu from "Components/LayerMenu";
import { LayerMenuCloseIcon } from "Media/Icons/LayerMenuIcons";
import useNotifications from "Hooks/useNotifications";
import ConfirmModal from "Components/ConfirmModal";
import MobileSettingsMenu from "Components/MobileSettingsMenu";
import AvailableProjectsForLogedUser from "Components/AvailableProjectsForLogedUser";
import BaseMapControl from "Components/BaseMapControl";
import ObjectOverlay from "Components/ObjectOverlay";

/*Imports for Measure Tool*/
import Draw from "ol/interaction/Draw";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import { getArea, getLength } from "ol/sphere";
import { unByKey } from "ol/Observable";
import useMap from "Hooks/useMap";
import Olcs3D from "Components/Olcs3D";

export default function MapPage() {
    // eslint-disable-next-line no-unused-vars
    const { setMessage } = useNotifications();

    const [isCookieActive, setIsCookieActive] = useState(true);
    const [positioning, setPositioning] = useState(false);
    const [showModal, setShowModal] = useState(true);

    const doesCookieExist = () => {
        return document.cookie.match(RegExp("(?:^|;\\s*)username=([^;]*)"));
    };

    useState(() => {
        if (doesCookieExist()) {
            setIsCookieActive(false);
        }
        /* setMessage("Successfully uploaded", "success"); // Remove this to remove notification */
    }, []);

    //const [map, setMap] = useState();
    const { map, setMap } = useMap();
    const [geolocation, setGeolocation] = useState();
    const [active3d, setActive3d] = useState({ active: false, available: false });

    // Map definitions

    const mapElement = useRef();
    const mapRef = useRef();
    mapRef.current = map;

    const osmLayer = new OSM();
    const mapViewParams = {
        projection: "EPSG:3857",
        center: fromLonLat([16.450145, 44.515856]),
        zoom: 7,
        maxZoom: 18,
        minZoom: 3,
        extent: transformExtent([10.0, 41.0, 22.0, 48.0], "EPSG:4326", "EPSG:3857"),
    };

    const mapView = new View({
        ...mapViewParams,
        smoothExtentConstraint: true,
    });

    const zoomToExtent = new ZoomToExtent({
        extent: mapViewParams.extent,
        center: mapViewParams.center,
    });

    const scaleLine = new ScaleLine({
        className: "ol-scale-line",
        minWidth: 100,
        maxWidth: 150,
        units: "metric",
    });

    useEffect(() => {
        // create map

        const initialMap = new Map({
            target: mapElement.current,
            layers: [
                // OSM
                new TileLayer({
                    title: "OSM",
                    source: osmLayer,
                    type: "base",
                    visible: true,
                }),
                //DOF
                new TileLayer({
                    title: "DOF",
                    type: "base",
                    source: new TileWMS({
                        url: "https://geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms",
                        /* url: "https://wms-proxy.listlabs.net/geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms", */ //3D
                        params: { LAYERS: "OI.OrthoImagery", TILED: true },
                        crossOrigin: null,
                    }),
                }),
            ],
            view: mapView,
            controls: defaultControls().extend([zoomToExtent, scaleLine]),
        });

        // save map and vector layer references to state
        setMap(initialMap);
        //const rasterMap = setMap(initialMap);

        const geolocationInstance = new Geolocation({
            // enableHighAccuracy must be set to true to have the heading value.
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: mapView.getProjection(),
        });

        geolocationInstance.on("error", function (error) {
            console.log("Error occured: " + error);
        });

        const accuracyFeature = new Feature();
        geolocationInstance.on("change:accuracyGeometry", function () {
            accuracyFeature.setGeometry(geolocationInstance.getAccuracyGeometry());
        });

        const positionFeature = new Feature();
        positionFeature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: "#3399CC",
                    }),
                    stroke: new Stroke({
                        color: "#fff",
                        width: 2,
                    }),
                }),
            })
        );

        geolocationInstance.on("change:position", function () {
            const coordinates = geolocationInstance.getPosition();
            positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
        });

        setGeolocation(geolocationInstance);

        let geolocationLayer = new VectorLayer({
            map: map,
            source: new VectorSource({
                features: [accuracyFeature, positionFeature],
            }),
            className: "ol-geolocation-layer",
            name: "geolocation-layer",
        });

        initialMap.addLayer(geolocationLayer);

        return () => {
            try {
                geolocationInstance.off("change:accuracyGeometry", function () {
                    accuracyFeature.setGeometry(geolocationInstance.getAccuracyGeometry());
                });

                geolocationInstance.off("change:position", function () {
                    const coordinates = geolocationInstance.getPosition();
                    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
                });
            } catch (error) {
                error;
            }
        };
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    const toggleTracking = () => {
        const trackingStatus = geolocation.getTracking();

        if (trackingStatus === false) {
            geolocation.setTracking(true);
            map.getLayers().forEach((el) => {
                if (el.get("name") === "geolocation-layer") {
                    el.setVisible(true);
                    //console.log(el.getFeatures());
                }
            });
        } else if (trackingStatus === true) {
            geolocation.setTracking(false);
            map.getLayers().forEach((el) => {
                if (el.get("name") === "geolocation-layer") {
                    el.setVisible(false);
                }
            });
        }
    };

    const [isLayerMenuOpen, setLayerMenuOpen] = useState(false);
    function setLayerMenu() {
        setLayerMenuOpen(!isLayerMenuOpen);
    }

    const handle3dClick = () => {
        if (!active3d.active) {
            setActive3d((prev) => {
                return { ...prev, active: true };
            });
        } else {
            setActive3d((prev) => {
                return { ...prev, active: false };
            });
        }
    };

    return (
        <div className="z-0 h-screen overflow-hidden wrapper">
            <div className="relative bottom-0 top-16 md:top-14">
                <div
                    ref={mapElement}
                    className="map-container"
                >
                    <AvailableProjectsForLogedUser />
                    {/*DESKTOP LAYER MENU*/}
                    <div
                        className="absolute z-10 md:z-[6000]"
                        onClick={setLayerMenu}
                    >
                        {isLayerMenuOpen ? (
                            <button className="md:w-[56px] md:block md:h-[56px] md:bg-orange-100 md:top-0 md:left-auto mobile-layer-icon-closed hidden">
                                <LayerMenuIcon />
                            </button>
                        ) : (
                            <button
                                className="bg-white-100 w-[55px] h-[55px] absolute top-2 left-2.5 rounded-[10px] flex justify-center items-center hover:bg-green-100 hover-state 
                            md:bg-orange-100 md:hover:bg-orange-100 md:w-[56px] md:h-[56px] md:left-0 mobile-layer-icon-closed md:top-0 md:mt-[-56px] md:rounded-none shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]"
                            >
                                <LayerMenuIcon />
                            </button>
                        )}
                    </div>
                    <div className="z-[6000]">
                        {isLayerMenuOpen && <LayerMenu isLayerMenuOpen={setLayerMenuOpen} />}
                    </div>

                    <button
                        onClick={toggleTracking}
                        className="ol-locate-button"
                    >
                        <PositionIcon />
                    </button>
                </div>
            </div>
            <MobileSettingsMenu />
            <ObjectOverlay />
            <HeaderComponent
                mapRef={map}
                handle3dClick={handle3dClick}
                testMeasure={getLength}
                active3d={active3d}
                isLayerMenuOpen={setLayerMenuOpen}
            />
            <Notification />
            {/* <ConfirmModal
                show={showModal}
                title="Brisanje podataka"
                handleCancel={closeModal}
                handleConfirm={closeModal}
                bodyText="Jeste li sigurni da želite obrisati odabrane podatke? Svi Vaši podaci bit će trajno uklonjeni. Ova akcija se ne može poništiti."
            /> */}
            <BaseMapControl />
            {isCookieActive && <CookieComponent />}
            <Olcs3D
                active3d={active3d}
                setActive3d={setActive3d}
            />
        </div>
    );
}
