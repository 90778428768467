import React, { useRef, useEffect, useState } from "react";

import { Overlay } from "ol";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import useMap from "Hooks/useMap";
import useAxiosPrivate from "Hooks/useAxiosPrivate";
import "../Style/popup.css";
import "../Style/carousel.css";
import CloseIcon from "Media/Icons/CloseIcon";

const ObjectOverlay = () => {
    const overlayContainer = useRef();
    const [clickedObject, setClickedObject] = useState({});
    const [pictures, setPictures] = useState([]);
    const { map } = useMap();
    const axiosPrivate = useAxiosPrivate();
    const [overlay, setOverlay] = useState(null);

    useEffect(() => {
        // Getting pictures for clicked object
        setPictures(null);
        const getPictures = async () => {
            const { data } = await axiosPrivate.get(`/api/habitats/objects/${clickedObject.id}/photos/`);
            setPictures(data);
        };

        if (clickedObject?.id) {
            getPictures();
        }
    }, [clickedObject]);
    console.log(clickedObject);

    useEffect(() => {
        if (map) {
            const overlay = new Overlay({
                element: overlayContainer.current,
                positioning: "center-center",
                autoPan: true,
                autoPanAnimation: {
                    duration: 10,
                },
                id: "popup",
            });
            map.addOverlay(overlay);
            setOverlay(overlay);

            map.on("click", overlayClickHandler);
        }

        return () => {
            if (map) {
                map.un("click", overlayClickHandler);
                const overlays = map.getOverlays().getArray();
                console.log({ overlays });
                overlays.forEach((overlay) => {
                    map.removeOverlay(overlay);
                });
            }
        };
    }, [map]);

    const overlayClickHandler = (e) => {
        let overlays = map.getOverlays().getArray();
        let [overlay] = overlays.filter((overlay) => overlay.id === "popup");
        overlay.setPosition(null);
        map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
            let clickedCoordinate = e.coordinate;
            let clickedFeature = layer.get("object");
            setClickedObject(clickedFeature);

            overlay.setPosition(clickedCoordinate);
        });
    };

    /* useEffect(() => {
        if (map && clickedObject) {
            map.on("click", (e) => {
                overlay.setPosition(null);
                map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
                    let clickedCoordinate = e.coordinate;
                    let clickedFeature = layer.get("object");
                    setClickedObject(clickedFeature);

                    overlay.setPosition(clickedCoordinate);
                });
            });
            map.addOverlay(overlay);
        }
        return () => {
            if (map) {
                const overlays = map.getOverlays().getArray();
                overlays.forEach((overlay) => {
                    map.removeOverlay(overlay);
                });
            }
        };
    }); */

    return (
        <>
            <div>
                <div
                    ref={overlayContainer}
                    className="ol-popup font-inter w-[325px]"
                >
                    <div className="flex justify-between items-center px-2 border-b-[1px] border-[#0000000d] mt-4">
                        <div className="text-green-100 text-[20px]">Stanište</div>
                        <div
                            className="cursor-pointer"
                            onClick={() => overlay.setPosition(null)}
                        >
                            <CloseIcon />
                        </div>
                    </div>
                    <div className="mt-[26px] ">
                        <div className="text-sm text-green-100 ml-5">NAZIV</div>
                        <div className="mt-[2px] border-[1px] w-full h-10 rounded-3xl flex items-center px-[18px] border-gray-500 text-gray-400">
                            <span
                                title={clickedObject.name}
                                className="overflow-hidden inline-block text-ellipsis whitespace-nowrap"
                            >
                                {clickedObject.name}
                            </span>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-sm text-green-100 ml-5">KLASA</div>
                        <div className="mt-[2px] border-[1px] w-full h-10 rounded-3xl flex items-center px-[18px] border-gray-500 text-gray-400">
                            <span
                                title={clickedObject.habitat_class_name}
                                className="overflow-hidden inline-block text-ellipsis whitespace-nowrap"
                            >
                                {clickedObject.habitat_class_name}
                            </span>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="text-sm text-green-100 ml-5">FOTOGRAFIJA</div>
                        <div
                            className={`${
                                pictures?.results?.length
                                    ? "border-none h-[180px]"
                                    : "border-gray-500 border-[1px] px-[18px] py-2.5 text-gray-500 text-m h-[90px]"
                            } mt-[2px] w-full rounded-3xl flex overflow-hidden`}
                        >
                            {pictures?.results?.length ? (
                                <Carousel className="w-full h-[180px]">
                                    {pictures.results.map((picture) => {
                                        return (
                                            <img
                                                key={picture.id}
                                                className="w-full bottom-0 object-cover h-[180px] mx-0 my-0 rounded-2xl"
                                                src={picture.img}
                                            />
                                        );
                                    })}
                                </Carousel>
                            ) : (
                                <i>Ne postoji slikovni prikaz za odabrani objekt.</i>
                            )}
                        </div>
                    </div>
                    <div className="mt-[22px] text-m px-[18px] py-2.5 mb-10 h-[110px] leading-5 bg-[#0060461a] rounded-l-xl rounded-r-md text-black overflow-y-auto scrollbar text-[#54545480]">
                        {clickedObject.comment ? (
                            <>{clickedObject.comment}</>
                        ) : (
                            <i>Ne postoji komentar za odabrani projekt.</i>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ObjectOverlay;
