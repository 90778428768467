import useMap from "Hooks/useMap";
import { PolygonAddIcon } from "Media/Icons/HeaderComponentIcons";
import { PolygonDrawIcon, RemoveImage, CloseX } from "Media/Icons/PolygonDrawIcons";
import React, { useEffect, useRef, useState } from "react";

import "../Style/polygonAdd.css";

import Draw from "ol/interaction/Draw";
import { Vector as VectorSource } from "ol/source";
import { Vector } from "ol/layer";
import WKT from "ol/format/WKT";
import useProjects from "Hooks/useProjects";
import { AddImage } from "Media/Icons/PolygonDrawIcons";
import Select from "react-select";
import selectPolygonAdd from "Utils/selectPolygonAdd";
import useNotifications from "Hooks/useNotifications";
import useAxiosPrivate from "Hooks/useAxiosPrivate";
import useProjectObjects from "Hooks/useProjectObjects";

const PolygonAdd = ({ active3d }) => {
    const [drawActive, setDrawActive] = useState(false);
    const [drawnFeature, setDrawnFeature] = useState();
    const [drawInteraction, setDrawInteraction] = useState();
    const [drawSource, setDrawSource] = useState();
    const [imageFiles, setImageFiles] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        classInstance: "",
        comment: "",
    });

    const { map } = useMap();
    const { selectedProjectForViewOnMap, classes } = useProjects();
    const { setMessage } = useNotifications();
    const { setRender } = useProjectObjects();
    const axiosPrivate = useAxiosPrivate();
    const hiddenInput = useRef();
    const nameInput = useRef();
    const classInput = useRef();

    const toggleDraw = () => {
        setDrawActive((prev) => !prev);
    };

    const handleDrawEnd = (e) => {
        // Pause drawing until data is added
        e.target.setActive(false);

        setDrawnFeature(e.feature);
    };

    useEffect(() => {
        // Add draw interaction
        let vectorForDrawing, newDrawInteraction;
        if (drawActive && map) {
            let newSource = new VectorSource();

            vectorForDrawing = new Vector({
                source: newSource,
            });

            setDrawSource(newSource);

            map.addLayer(vectorForDrawing);

            newDrawInteraction = new Draw({
                source: newSource,
                type: "Polygon",
                /* style: new Style({
                    fill: new Fill({
                        color: "#FF040410",
                    }),
                    stroke: new Stroke({
                        color: "#FF0404",
                        width: 2,
                    }),
                }) */
            });
            map.addInteraction(newDrawInteraction);

            setDrawInteraction(newDrawInteraction);

            newDrawInteraction.on("drawend", handleDrawEnd);
        }

        return () => {
            // remove draw interaction
            if (newDrawInteraction && map) {
                map.removeLayer(vectorForDrawing);
                map.removeInteraction(newDrawInteraction);
                closeDialog();
            }
        };
    }, [drawActive]);

    // Snippet to trigger hidden input, otherwise it is hard to style upload button
    const handleUploadClick = () => {
        hiddenInput.current.click();
    };

    // Upload image and add to list
    const handleUpload = (e) => {
        // Add logic for deduplication of images?

        let imageArr = Array.from(e.target.files);
        setImageFiles((prev) => {
            return prev.concat(imageArr);
        });

        // Clean input values to prevent bug on same image add
        e.target.value = null;
    };

    // Remove uploaded image from list
    const removeImage = (fileToRemove) => {
        setImageFiles((prev) => {
            return prev.filter((file) => file !== fileToRemove);
        });
    };

    // Close dialog on cancel, unmount or submit
    const closeDialog = (createdFeature) => {
        // Clear all form data
        setImageFiles([]);
        setFormData(null);

        // If function triggered from cancel button or unmount remove last drawn feature
        if (!createdFeature?.data?.id && drawnFeature) {
            drawSource.removeFeature(drawnFeature);
        }
        // Reset drawnFeature
        setDrawnFeature(null);

        // Enable newDrawInteraction again if it was initialized
        if (drawInteraction) {
            drawInteraction.setActive(true);
        }
    };

    // All in one function to fill formData
    const handleChange = (e) => {
        // Diferentiate Select from input
        if (e?.target?.name) {
            const name = e.target.name;
            const value = e.target.value;
            setFormData((prev) => {
                return { ...prev, [name]: value };
            });
        } else {
            // If first condition fails event is fired from <Select/> component
            setFormData((prev) => {
                return { ...prev, classInstance: e.value };
            });
        }
    };

    const sendFeature = async () => {
        const bodyFormData = new FormData();
        const WKTWriter = new WKT();
        let WKTstring = WKTWriter.writeFeature(drawnFeature);

        bodyFormData.append("name", formData.name);
        bodyFormData.append("geom", WKTstring);
        bodyFormData.append("habitat_class", formData.classInstance.id);
        bodyFormData.append("project", selectedProjectForViewOnMap.value.id);
        bodyFormData.append("comment", formData.comment);

        return axiosPrivate.post("/api/habitats/objects/", bodyFormData);
    };

    const sendImage = async (featureId, image) => {
        const bodyFormData = new FormData();
        bodyFormData.append("img", image);
        return axiosPrivate.post(`/api/habitats/objects/${featureId}/photos/`, bodyFormData);
    };

    const handleSubmit = async () => {
        // This should be handeled better with validation library!

        // Check if feature exists, this is probably too complex and too nested
        if (drawnFeature) {
            // Validate form ?
            if (formData?.classInstance && formData?.name) {
                // If all ok, send to backend
                let createdPolygon, errorObject;
                try {
                    createdPolygon = await sendFeature();
                } catch (error) {
                    errorObject = true;
                    setMessage("Došlo je do pogreške prilikom kreiranja objekta!", "error");
                }
                // Send images to backend
                let errorImages = 0;

                if (createdPolygon?.data?.id && imageFiles.length > 0) {
                    for (let image of imageFiles) {
                        try {
                            await sendImage(createdPolygon.data.id, image);
                        } catch (error) {
                            errorImages += 1;
                            setMessage(`Fotografija "${image.name}" nije učitana, pokušajte ponovno!`, "warning");
                        }
                    }
                }

                // Success messages
                if (!errorObject) {
                    errorImages === 0
                        ? setMessage(
                              "Objekt i fotografije uspješno učitani! Objekt će biti prikazan nakon odobrenja!",
                              "success"
                          )
                        : setMessage(
                              `Došlo je do pogreške kod učitavanja fotografija, objekt je kreiran i biti će prikazan nakon odobrenja. Broj neučitanih fotografija ${errorImages}`,
                              "info"
                          );

                    // reload features
                    setRender((prev) => !prev);
                    closeDialog(createdPolygon);
                }
            } else {
                // Missing data - should not be possible to trigger due to disabled button
                if (!formData?.classInstance && !formData.name) {
                    // If no name and no class
                    setMessage("Naziv i klasa su obavezni atributi!", "error");
                    nameInput.current.focus();
                } else if (!formData?.classInstance) {
                    // If class is missing
                    setMessage("Potrebno je odabrati klasu!", "error");
                    classInput.current.focus();
                } else if (!formData?.name) {
                    // If name is missing
                    setMessage("Potrebno je unijeti ime!", "error");
                    nameInput.current.focus();
                }
            }
        } else {
            // Notifiy no feature found
            setMessage("Poligon ne sadrži ispravnu geometriju, pokušajte ponovno!", "error");
        }
        // Check if formData is valid
        // Send data and get feature Id
        // If images send images to created instance
    };

    return (
        <>
            <button
                title="Dodaj poligon"
                className={`${
                    drawActive ? "bg-white-100 text-green-500 button-active" : "bg-green-500"
                } group w-[44px] md:bg-white-100 h-[44px] flex justify-center items-center rounded-[50%] state-hover-color hover:bg-white-100 md:hidden disabled:hover:bg-green-500 ${
                    active3d?.active ? "button-disabled" : ""
                }`}
                onClick={toggleDraw}
                disabled={active3d?.active}
            >
                <PolygonAddIcon />
            </button>
            {/* Button in case other form of drawing is added */}
            {/* {drawActive && (
                <div className="font-inter absolute flex flex-col gap-y-0.5 top-[72px] ml-14 text-gray-400">
                    <button
                        title="Dodaj poligon"
                        className={`${"bg-white-100 text-gray-500 hover:text-gray-400"} group h-11 w-52 rounded-l-[20px] rounded-r-[20px] border-green-600 border-[1px] flex items-center`}
                    >
                        <PolygonDrawIcon />
                        <p className="font-inter w-2/3 text-gray-400 text-center">Dodaj poligon</p>
                    </button>
                </div>
            )} */}
            {drawActive && drawnFeature && (
                <div className="h-[404px] fixed flex flex-row top-1/3 left-1/3 text-gray-400 rounded-2xl shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]">
                    <div className="flex flex-col w-80 h-full bg-white-100 rounded-l-2xl px-6 py-3 ">
                        <div className="flex flex-row justify-between">
                            <h3 className="text-gray-400 text-lg ml-4">Dodaj poligon</h3>
                            <button
                                className="close-dialog"
                                onClick={closeDialog}
                            >
                                <CloseX />
                            </button>
                        </div>
                        <hr className="border-gray-400 border-opacity-5"></hr>
                        <form className="py-6 flex flex-col flex-grow">
                            <label
                                htmlFor="naziv"
                                className="pl-5 text-sm"
                            >
                                NAZIV
                            </label>
                            <input
                                id="naziv"
                                name="name"
                                ref={nameInput}
                                className="w-full h-10 mb-3 bg-white-100 rounded-[20px] border-gray-100 border-[1px] px-6"
                                onChange={handleChange}
                            ></input>
                            <label
                                htmlFor="klasa"
                                className="pl-5 text-sm"
                            >
                                KLASA
                            </label>
                            <Select
                                id="klasa"
                                name="class" // Does not get send in onChange!
                                ref={classInput}
                                styles={selectPolygonAdd}
                                className="w-full h-10 mb-3 bg-white-100"
                                options={classes?.results?.map((classInstance) => {
                                    return {
                                        value: classInstance,
                                        label: classInstance.eunis_name,
                                    };
                                })}
                                type="text"
                                placeholder="Odaberi klasu"
                                isSearchable
                                onChange={handleChange}
                                value={formData?.class}
                                components={{ IndicatorSeparator: () => null }}
                            />
                            <textarea
                                name="comment"
                                placeholder="Dodaj komentar..."
                                className="resize-none w-full flex-grow mt-10 bg-gray-950 rounded-[20px] border-gray-100 border-[1px] px-6 text-m"
                                onChange={handleChange}
                            ></textarea>
                        </form>
                        <div className="flex flex-row justify-end w-full mt-4 mb-2 font-inter">
                            <button
                                onClick={closeDialog}
                                className="flex justify-center items-center h-9 w-28 mx-[2.5px] rounded-[40px] border-[1px] hover:bg-gray-100 hover:bg-opacity-20 border-gray-700 text-green-100 text-m"
                            >
                                Odustani
                            </button>
                            <button
                                className="flex justify-center items-center h-9 w-24 ml-[2.5px] rounded-[40px] bg-green-100 hover:bg-opacity-80 text-white-100 text-m disabled:cursor-not-allowed
                                    disabled:bg-white-100 disabled:border-gray-200 disabled:border-[1px] disabled:border-opacity-[0.12] disabled:text-gray-250 disabled:text-opacity-20"
                                onClick={handleSubmit}
                                disabled={!formData?.classInstance || !formData?.name}
                            >
                                Pošalji
                            </button>
                        </div>
                    </div>
                    <div
                        className={`rounded-r-2xl h-full bg-gray-950 ${
                            imageFiles.length > 0 ? "w-44 text-black px-4 pt-5" : "w-11"
                        }`}
                    >
                        <div
                            className={`flex flex-row ${
                                imageFiles.length > 0 ? "justify-between" : "justify-center items-center mt-5"
                            } mb-1`}
                        >
                            {imageFiles.length > 0 && <h4 className="text-m text-gray-400">Dodaj fotografiju</h4>}
                            <button
                                className="image-hover-color flex flex-row items-center"
                                onClick={handleUploadClick}
                            >
                                <AddImage className />
                            </button>
                            {/* Hidden image input! */}
                            <input
                                ref={hiddenInput}
                                className="hidden"
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={handleUpload}
                            ></input>
                        </div>
                        {imageFiles.length > 0 && (
                            <>
                                <hr className="border-gray-400 border-opacity-5 mb-2"></hr>
                                <div className="flex flex-col">
                                    {imageFiles &&
                                        imageFiles.map((image, idx) => {
                                            // Display uploaded images
                                            return (
                                                <ul
                                                    key={"image-row-" + idx}
                                                    className="flex flex-row items-center justify-between my-1 font-inter list-disc marker:text-green-500 marker:text-base"
                                                >
                                                    {/* <svg className="h-[6px] w-3 fill-green-500 z-20">
                                                        <circle
                                                            cx="3"
                                                            cy="3"
                                                            r="3"
                                                        />
                                                    </svg> */}
                                                    <li
                                                        className="text-sm mx-2 text-gray-400"
                                                        key={"image " + idx}
                                                    >
                                                        {image.name}
                                                    </li>
                                                    <button onClick={() => removeImage(image)}>
                                                        <RemoveImage />
                                                    </button>
                                                </ul>
                                            );
                                        })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default PolygonAdd;
