/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";

import useProjectObjects from "Hooks/useProjectObjects";
import { LayerMenuIcon } from "Media/Icons/LayerMenuIcons";
import { LayerMenuCloseIcon } from "Media/Icons/LayerMenuIcons";

import "../Style/layerMenu.css";

const LayerMenu = ({ isLayerMenuOpen }) => {
    const { displayedVectorLayers, colorStyle } = useProjectObjects();
    const [classesBoxHeight, setClassesBoxHeight] = useState();
    const [classesContainerHeight, setClassesContainerHeight] = useState();
    const [forceRerender, setForceRerender] = useState(true);
    const classesBoxRef = useRef();
    const classesContainerRef = useRef();

    useEffect(() => {
        if (classesBoxRef.current) {
            setClassesBoxHeight(classesBoxRef.current.clientHeight + 22);
        }
        if (classesContainerRef.current) {
            setClassesContainerHeight(classesBoxHeight + 89);
        }
    }, [classesBoxHeight]);

    const handleOnCheckboxChange = (layer) => {
        let isLayerActive = layer.get("visible");
        layer.setVisible(!isLayerActive);
        setForceRerender(!forceRerender);
    };

    const getTailwindClasses = (vectorLayerClass) => {
        for (let i = 0; i < colorStyle.length; i++) {
            if (colorStyle[i].value === vectorLayerClass) {
                let color = colorStyle[i].colorLine;
                return color;
            }
        }
    };

    const renderClasses = displayedVectorLayers.map((vectorLayer, index) => {
        let active = vectorLayer.get("visible");

        let vectorLayerClass = vectorLayer.get("habitatClass");
        let color = getTailwindClasses(vectorLayerClass);
        return (
            <div
                key={vectorLayer.ol_uid}
                className="flex items-center"
            >
                <div className={`${active ? "bg-green-100" : "bg-green-600"} w-[13px] h-[2px] opacity-30`}></div>
                <div className={`${active ? "bg-green-100" : "bg-green-600"} w-[6px] h-[6px] rounded-[50%] mr-4`}></div>
                <input
                    id={vectorLayer.ol_uid}
                    type="checkbox"
                    value={vectorLayer}
                    name={vectorLayerClass}
                    checked={true}
                    style={
                        active
                            ? { backgroundColor: `${color}`, border: "none" }
                            : { backgroundColor: "transparent", border: `2px solid ${color}` }
                    }
                    className="w-4 h-4 rounded focus:ring-transparent border-2 cursor-pointer"
                    onChange={() => handleOnCheckboxChange(vectorLayer)}
                />
                <label
                    htmlFor={vectorLayer.ol_uid}
                    title={vectorLayerClass}
                    className="ml-2 text-m text-gray-400 cursor-pointer w-[250px] md:w-full overflow-hidden inline-block text-ellipsis whitespace-nowrap"
                >
                    {vectorLayerClass}
                </label>
            </div>
        );
    });

    return (
        <div className="md:mt-[-56px]">
            <div className="w-[60px] h-[60px] bg-green-100 z-[3005] opened-state rounded-tl-[10px] absolute top-2 left-2.5 flex items-center justify-center md:top-0 md:left-0 md:w-[55px] md:h-[55px] md:rounded-none">
                <LayerMenuIcon />
            </div>
            <div className="bg-green-500 custom-width w-[300px] z-[3005] md:h-[55px] h-[60px] text-white-100 font-medium absolute left-[70px] md:left-[55px] md:top-0 top-2 rounded-tr-[10px] flex justify-between items-center pl-7 pr-4 md:pr-0 shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]">
                <div>STANIŠTA</div>
                <div
                    onClick={() => {
                        isLayerMenuOpen((prev) => !prev);
                    }}
                    className="cursor-pointer md:hidden"
                >
                    <LayerMenuCloseIcon />
                </div>
            </div>
            <div
                ref={classesContainerRef}
                style={{ height: `${classesContainerHeight}px` }}
                className="font-inter bg-white-100 absolute top-[68px] left-2.5 md:w-full w-[360px] md:h-full h-[549px] rounded-b-[10px] md:top-[55px] md:left-0 z-[3005] shadow-[0_4px_15px_0px_rgb(0,0,0,10%)]"
            >
                <div
                    style={{ height: `${classesBoxHeight}px` }}
                    className="fixed ml-[30px] mt-[22px] w-[2px] bg-green-500 opacity-30"
                ></div>
                <div
                    ref={classesBoxRef}
                    className="flex flex-col justify-around align-center gap-[11px] text-gray-400 ml-[30px] mt-[55px]"
                >
                    {renderClasses}
                </div>
            </div>
        </div>
    );
};

export default LayerMenu;
