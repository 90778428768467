import React from "react";

/*DESKTOP ICONS*/
export const CompareIcon = ({isOpen}) => {
    return (
        <svg
            className="fill-current md:text-green-500 md:group-hover:text-white-100 group-hover:text-green-500"
            width="28"
            height="28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                className={`fill-current ${isOpen ? "md:text-white-100": "md:text-green-500"} md:group-hover:text-white-100 group-hover:text-green-500`}
                d="M11.667 4.667H5.833A2.34 2.34 0 0 0 3.5 7v14a2.34 2.34 0 0 0 2.333 2.333h5.834V28H14V0h-2.333v4.667Zm10.5 0h-5.834V7h5.834v14h-5.834v2.333h5.834A2.34 2.34 0 0 0 24.5 21V7a2.34 2.34 0 0 0-2.333-2.333Z"
                fill="#FDFDFD"
            />
        </svg>
    );
};

export const MeasureIcon = () => {
    return (
        <svg
            width="28"
            height="28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#a)">
                <path
                    d="M.578 14.486a1.155 1.155 0 0 0-.423 1.578l5.135 8.895a1.155 1.155 0 0 0 1.579.423l20.553-11.867a1.157 1.157 0 0 0 .423-1.578L22.71 3.042a1.156 1.156 0 0 0-1.579-.423L.578 14.486Zm2.156 1.423L21.286 5.198l3.98 6.893-1.337.772-1.491-2.584-1.001.578 1.491 2.584-1.53.884-1.492-2.584-1 .578 1.491 2.583-1.528.883-1.492-2.584-1 .578 1.491 2.584-1.53.883-2.073-3.59-1 .577 2.072 3.59-1.53.885-1.492-2.584-1 .577 1.491 2.584-1.528.883-1.492-2.584-1 .578 1.49 2.583-1.53.884-1.491-2.584-1.001.578 1.492 2.584-1.032.596-3.98-6.894Z"
                    fill="#fff"
                    className="fill-current md:text-green-500 md:group-hover:text-white-100 group-hover:text-green-500"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path
                        fill="#fff"
                        d="M0 0h28v28H0z"
                        className="fill-current md:text-green-500 md:group-hover:text-white-100 group-hover:text-green-500"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const PolygonMeasureIconInactive = () => {
    return (
        <svg
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10"
                cy="3"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <circle
                cx="25"
                cy="10"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <circle
                cx="16"
                cy="22"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <circle
                cx="3"
                cy="14"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <line
                x1="8.40687"
                y1="5.29062"
                x2="3.40687"
                y2="12.2906"
                stroke="#B1B1B1"
                className="fill-current group-hover:stroke-green-500"
            />
            <line
                x1="24.416"
                y1="10.2774"
                x2="16.416"
                y2="22.2773"
                stroke="#B1B1B1"
                className="fill-current group-hover:stroke-green-500"
            />
            <path
                d="M25.1077 11.3836L11.9998 4.00313"
                stroke="#B1B1B1"
                className="fill-current group-hover:stroke-green-500"
            />
            <line
                x1="3.27735"
                y1="14.584"
                x2="15.2774"
                y2="22.584"
                stroke="#B1B1B1"
                className="fill-current group-hover:stroke-green-500"
            />
        </svg>
    );
};

export const PolygonMeasureIcon = () => {
    return (
        <svg
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="group"
        >
            <circle
                cx="10"
                cy="3"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100 "
            />
            <circle
                cx="25"
                cy="10"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100 "
            />
            <circle
                cx="16"
                cy="22"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100 "
            />
            <circle
                cx="3"
                cy="14"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100 "
            />
            <line
                x1="8.40687"
                y1="5.29062"
                x2="3.40687"
                y2="12.2906"
                stroke="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100 "
            />
            <line
                x1="24.416"
                y1="10.2774"
                x2="16.416"
                y2="22.2773"
                stroke="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
            <path
                d="M25.1077 10.8019L11.9998 3.42147"
                stroke="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
            <line
                x1="3.27735"
                y1="14.584"
                x2="15.2774"
                y2="22.584"
                stroke="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
        </svg>
    );
};

export const PolygonMeasureIconHeader = ({ isMeasureActive, selectedInteraction }) => {
    return (
        <svg
            width="28"
            height="25"
            viewBox="0 0 28 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="10"
                cy="3"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <circle
                cx="25"
                cy="10"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <circle
                cx="16"
                cy="22"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <circle
                cx="3"
                cy="14"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <line
                x1="8.40687"
                y1="5.29062"
                x2="3.40687"
                y2="12.2906"
                stroke="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:stroke-white-100" : ""
                }`}
            />
            <line
                x1="24.416"
                y1="10.2774"
                x2="16.416"
                y2="22.2773"
                stroke="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:stroke-white-100" : ""
                }`}
            />
            <path
                d="M25.1077 11.3836L11.9998 4.00313"
                stroke="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:stroke-white-100" : ""
                }`}
            />
            <line
                x1="3.27735"
                y1="14.584"
                x2="15.2774"
                y2="22.584"
                stroke="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "area" ? "fill-current md:stroke-white-100" : ""
                }`}
            />
        </svg>
    );
};

export const LineMeasureIconInactive = () => {
    return (
        <svg
            width="23"
            height="11"
            viewBox="0 0 23 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="3"
                cy="8"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <circle
                cx="20"
                cy="3"
                r="3"
                fill="#B1B1B1"
                className="fill-current group-hover:text-green-500"
            />
            <line
                x1="3.87873"
                y1="7.51493"
                x2="19.8787"
                y2="3.51493"
                stroke="#B1B1B1"
                className="fill-current group-hover:stroke-green-500"
            />
        </svg>
    );
};

export const LineMeasureIcon = () => {
    return (
        <svg
            width="23"
            height="11"
            viewBox="0 0 23 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="group"
        >
            <circle
                cx="3"
                cy="8"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
            <circle
                cx="20"
                cy="3"
                r="3"
                fill="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
            <line
                x1="3.87873"
                y1="7.51493"
                x2="19.8787"
                y2="3.51493"
                stroke="#FEFEFE"
                className="fill-current md:text-green-500 md:group-hover:text-white-100"
            />
        </svg>
    );
};

export const LineMeasureIconHeader = ({ isMeasureActive, selectedInteraction }) => {
    return (
        <svg
            width="23"
            height="11"
            viewBox="0 0 23 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="3"
                cy="8"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "length" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <circle
                cx="20"
                cy="3"
                r="3"
                fill="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "length" ? "fill-current md:text-white-100" : ""
                }`}
            />
            <line
                x1="3.87873"
                y1="7.51493"
                x2="19.8787"
                y2="3.51493"
                stroke="#197058"
                className={`${
                    isMeasureActive && selectedInteraction === "length" ? "fill-current stroke-white-100" : ""
                }`}
            />
        </svg>
    );
};

export const ThreeDIcon = () => {
    return (
        <svg
            width="31"
            height="19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.705 18.239c-1.125 0-2.128-.194-3.009-.58-.875-.386-1.571-.923-2.088-1.61-.511-.694-.79-1.498-.835-2.413H2.92c.046.563.24 1.049.58 1.458.34.403.787.716 1.338.937a4.87 4.87 0 0 0 1.832.333c.75 0 1.415-.131 1.995-.392.58-.262 1.034-.625 1.363-1.091.33-.466.495-1.006.495-1.62 0-.642-.16-1.207-.477-1.696-.319-.494-.785-.88-1.398-1.159-.614-.278-1.364-.417-2.25-.417H5V8.114h1.398c.693 0 1.3-.125 1.824-.375.528-.25.94-.603 1.235-1.057.302-.455.452-.989.452-1.602 0-.591-.13-1.106-.392-1.543a2.681 2.681 0 0 0-1.108-1.023c-.472-.244-1.028-.366-1.67-.366-.603 0-1.17.11-1.705.332-.528.216-.96.531-1.295.946-.336.41-.517.904-.546 1.483H1.148c.034-.915.31-1.716.826-2.403.517-.693 1.194-1.233 2.029-1.62.84-.386 1.764-.58 2.77-.58 1.08 0 2.005.22 2.778.657.773.432 1.367 1.003 1.781 1.713.415.71.622 1.477.622 2.301 0 .983-.258 1.821-.775 2.514-.511.694-1.207 1.174-2.088 1.44v.137c1.102.182 1.963.65 2.582 1.406.62.75.93 1.68.93 2.787 0 .95-.26 1.802-.776 2.557-.512.75-1.21 1.341-2.097 1.773-.886.432-1.895.648-3.025.648ZM21.78 18h-5.386V.545h5.625c1.693 0 3.142.35 4.346 1.049 1.205.693 2.128 1.69 2.77 2.991.642 1.296.963 2.847.963 4.654 0 1.818-.323 3.383-.971 4.696-.648 1.306-1.591 2.312-2.83 3.017C25.06 17.65 23.554 18 21.781 18Zm-3.272-1.875h3.136c1.443 0 2.64-.278 3.588-.835a5.148 5.148 0 0 0 2.122-2.378c.466-1.029.699-2.253.699-3.673 0-1.41-.23-2.623-.69-3.64-.46-1.022-1.148-1.806-2.063-2.352-.915-.551-2.054-.827-3.417-.827h-3.375v13.705Z"
                fill="#FEFEFE"
            />
        </svg>
    );
};

export const InfoIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="m14.987 8.15-6.048.758-.216 1.004 1.19.216c.774.186.928.464.76 1.238l-1.95 9.154c-.51 2.366.28 3.48 2.134 3.48 1.44 0 3.108-.664 3.866-1.578l.232-1.098c-.526.464-1.3.65-1.81.65-.726 0-.988-.51-.804-1.408L14.987 8.15Zm.182-5.51a2.64 2.64 0 1 1-5.28 0 2.64 2.64 0 0 1 5.28 0Z"
                fill="#FEFEFE"
                className="fill-current md:text-green-100 md:group-hover:text-white-100"
            />
        </svg>
    );
};

export const FingerprintIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.955 12.625a.956.956 0 0 1-.759-.364.929.929 0 0 1-.067-1.04C4.334 9.21 8 4.516 16 4.516c3.467 0 6.508.91 9.033 2.705 2.079 1.473 3.244 3.14 3.799 3.944a.925.925 0 0 1-.25 1.3.97.97 0 0 1-1.328-.25C26.25 10.77 23.187 6.374 16 6.374c-7.016 0-10.187 4.044-11.22 5.779a.938.938 0 0 1-.825.471Z"
                fill="#FEFEFE"
                className="hover:text-green-100 group-hover:fill-current group-hover:text-green-700"
            />
            <path
                d="M20.03 31a.959.959 0 0 1-.236-.027c-5.803-1.437-7.97-7.239-8.058-7.48l-.014-.053c-.048-.168-1.212-4.146.575-6.476.82-1.062 2.066-1.607 3.712-1.607 1.53 0 2.633.476 3.392 1.46.625.804.875 1.795 1.117 2.75.508 1.989.875 3.033 2.987 3.14.928.047 1.537-.495 1.883-.957.934-1.26 1.096-3.313.392-5.125-.905-2.34-4.117-6.75-9.78-6.75-2.418 0-4.639.777-6.42 2.237-1.474 1.21-2.642 2.917-3.205 4.672-1.043 3.267.325 8.404.338 8.45a.927.927 0 0 1-.68 1.141.962.962 0 0 1-1.17-.663c-.063-.234-1.524-5.712-.32-9.488 1.313-4.092 5.364-8.217 11.459-8.217 2.817 0 5.478.957 7.7 2.766 1.72 1.406 3.124 3.295 3.86 5.183.934 2.41.683 5.116-.638 6.883-.88 1.18-2.133 1.795-3.52 1.729-3.617-.182-4.267-2.706-4.74-4.547-.488-1.89-.8-2.8-2.662-2.8-1.023 0-1.74.282-2.188.864-.609.797-.656 2.043-.589 2.946.044.63.151 1.254.319 1.862.149.375 2.085 5.125 6.724 6.274a.929.929 0 0 1 .688 1.132.96.96 0 0 1-.925.701Z"
                fill="#FEFEFE"
                className="group-hover:fill-current group-hover:text-green-800"
            />
            <path
                d="M12.582 30.571a.97.97 0 0 1-.698-.294c-2.322-2.438-3.636-5.163-4.13-8.571v-.019c-.278-2.256.129-5.45 2.12-7.646 1.469-1.621 3.534-2.445 6.128-2.445 3.067 0 5.478 1.426 6.981 4.118 1.09 1.956 1.307 3.905 1.313 3.985a.943.943 0 0 1-.854 1.025.954.954 0 0 1-1.049-.83 9.626 9.626 0 0 0-1.116-3.332c-1.165-2.055-2.938-3.1-5.282-3.1-2.025 0-3.61.61-4.7 1.812-1.57 1.735-1.874 4.407-1.659 6.174.433 3.014 1.592 5.411 3.637 7.554a.921.921 0 0 1-.048 1.32.965.965 0 0 1-.643.25Z"
                fill="#FEFEFE"
                className="group-hover:fill-current group-hover:text-green-700"
            />
            <path
                d="M23.281 27.886c-2.031 0-3.758-.562-5.14-1.68-2.776-2.237-3.087-5.88-3.101-6.034a.955.955 0 0 1 1.903-.148c.007.054.284 3.034 2.424 4.75 1.267 1.012 2.96 1.413 5.045 1.178a.95.95 0 0 1 1.057.824.933.933 0 0 1-.84 1.032 11.67 11.67 0 0 1-1.348.078ZM24.886 3.05C24.094 2.534 21.284 1 16 1c-5.546 0-8.363 1.694-8.986 2.125a.724.724 0 0 0-.115.087.022.022 0 0 1-.014.007.93.93 0 0 0-.318.697.941.941 0 0 0 .957.928.972.972 0 0 0 .555-.175c.027-.02 2.451-1.8 7.923-1.8 5.472 0 7.91 1.774 7.936 1.787.166.124.368.19.575.188a.941.941 0 0 0 .956-.93.932.932 0 0 0-.583-.865Z"
                fill="#FEFEFE"
                className="group-hover:fill-current group-hover:text-green-500"
            />
        </svg>
    );
};

export const PolygonAddIcon = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="12" r="3" fill="white"/>
            <circle cx="23" cy="32" r="3" fill="white"/>
            <circle cx="9" cy="24" r="3" fill="white"/>
            <line x1="15.4069" y1="14.2906" x2="10.4069" y2="21.2906" stroke="#FEFEFE"/>
            <line x1="30.416" y1="22.2774" x2="24.416" y2="31.2773" stroke="#FEFEFE"/>
            <path d="M26.7923 15.4528L18.9999 12.4214" stroke="#FEFEFE"/>
            <line x1="10.2774" y1="23.584" x2="22.2773" y2="31.584" stroke="#FEFEFE"/>
            <path d="M32.3333 9C33.2964 9 34.25 9.18969 35.1397 9.55824C36.0294 9.92678 36.8378 10.467 37.5188 11.148C38.1997 11.8289 38.7399 12.6374 39.1084 13.5272C39.477 14.4169 39.6667 15.3705 39.6667 16.3336C39.6667 17.2967 39.477 18.2503 39.1084 19.14C38.7399 20.0298 38.1997 20.8382 37.5188 21.5192C36.8378 22.2002 36.0294 22.7404 35.1397 23.109C34.25 23.4775 33.2964 23.6672 32.3333 23.6672C30.3884 23.6672 28.5231 22.8945 27.1479 21.5192C25.7726 20.1439 25 18.2786 25 16.3336C25 14.3886 25.7726 12.5233 27.1479 11.148C28.5231 9.77264 30.3884 9 32.3333 9ZM32.3333 11.0001L32.2133 11.0107C32.0801 11.0351 31.9575 11.0994 31.8617 11.1952C31.766 11.2909 31.7017 11.4135 31.6773 11.5468L31.6667 11.6668L31.6653 15.6669H27.6667L27.5467 15.6776C27.4135 15.7019 27.2908 15.7662 27.1951 15.862C27.0993 15.9577 27.035 16.0804 27.0107 16.2136L27 16.3336L27.0107 16.4536C27.035 16.5868 27.0993 16.7095 27.1951 16.8052C27.2908 16.901 27.4135 16.9653 27.5467 16.9896L27.6667 17.0003H31.6653L31.6667 21.0004L31.6773 21.1204C31.7017 21.2536 31.766 21.3763 31.8617 21.472C31.9575 21.5678 32.0801 21.6321 32.2133 21.6565L32.3333 21.6671L32.4533 21.6565C32.5865 21.6321 32.7092 21.5678 32.8049 21.472C32.9007 21.3763 32.965 21.2536 32.9893 21.1204L33 21.0004L32.9987 17.0003H37L37.12 16.9896C37.2532 16.9653 37.3758 16.901 37.4716 16.8052C37.5673 16.7095 37.6317 16.5868 37.656 16.4536L37.6667 16.3336L37.656 16.2136C37.6317 16.0804 37.5673 15.9577 37.4716 15.862C37.3758 15.7662 37.2532 15.7019 37.12 15.6776L37 15.6669H32.9987L33 11.6668L32.9893 11.5468C32.965 11.4135 32.9007 11.2909 32.8049 11.1952C32.7092 11.0994 32.5865 11.0351 32.4533 11.0107L32.3333 11.0001Z" fill="#FEFEFE"/>
        </svg>
    );
};


/*MOBILE ICONS*/
export const SettingsIcon = () => {
    return (
        <svg
            width="30"
            height="30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 1v4l-2 1-3-3-4 4 3 3-1 2H1v6h4l1 2-3 3 4 4 3-3 2 1v4h6v-4l2-1 3 3 4-4-3-3 1-2h4v-6h-4l-1-2 3-3-4-4-3 3-2-1V1h-6Z"
                fill="#FEFEFE"
                stroke="#006046"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 19a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                fill="#FEFEFE"
                stroke="#006046"
                strokeOpacity=".8"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const SettingsIconOpened = () => {
    return (
        <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 2v4l-2 1-3-3-4 4 3 3-1 2H2v6h4l1 2-3 3 4 4 3-3 2 1v4h6v-4l2-1 3 3 4-4-3-3 1-2h4v-6h-4l-1-2 3-3-4-4-3 3-2-1V2h-6Z"
                fill="#006046"
                fillOpacity=".8"
                stroke="#006046"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                fill="#FEFEFE"
                stroke="#006046"
                strokeOpacity=".6"
                strokeWidth="1.75"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MobileLoginIcon = () => {
    return (
        <svg
            width="26"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 1C6.372 1 1 6.372 1 13s5.372 12 12 12 12-5.372 12-12S19.628 1 13 1Z"
                stroke="#FEFEFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 20.5s2.163-3 7.5-3c5.336 0 7.5 3 7.5 3M13 13a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2v0Z"
                stroke="#FEFEFE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export const MobileVisual = () => {
    return (
        <svg
            width="5"
            height="26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke="#006046"
                strokeOpacity=".3"
                d="M2.5 21V0"
            />
            <circle
                cx="2.5"
                cy="23.5"
                r="2.5"
                fill="#006046"
                fillOpacity=".3"
            />
        </svg>
    );
};

export const Search = ({ isSearchOpen }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
        >
            <path
                stroke="#197058"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m28.282 27.716-4.89-5.07a11.134 11.134 0 0 0 3.208-7.319 11.097 11.097 0 0 0-2.546-7.565 11.523 11.523 0 0 0-7.023-4.013c-2.762-.452-5.598.1-7.972 1.555a11.272 11.272 0 0 0-4.92 6.342 11.036 11.036 0 0 0 .603 7.944 11.369 11.369 0 0 0 5.824 5.558 11.69 11.69 0 0 0 8.12.382"
                className={`${isSearchOpen ? "stroke-white-100" : ""}`}
            />
        </svg>
    );
};
