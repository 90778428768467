import React, { useState, createContext } from "react";
import VectorSource from "ol/source/Vector";

const VectorSourceContext = createContext();

export const VectorSourceProvider = ({ children }) => {
    const [source] = useState(new VectorSource());

    return <VectorSourceContext.Provider value={{ source }}>{children}</VectorSourceContext.Provider>;
};

export default VectorSourceContext;
