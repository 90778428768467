import React, { useState, useRef, useEffect } from "react";

import { ThreeDIcon, FingerprintIcon, MobileLoginIcon } from "Media/Icons/HeaderComponentIcons";
import "../Style/header.css";
import MeasureComponent from "./MeasureComponent";
import { RegisteredUserMenu } from "./RegisteredUserMenu";
import InfoIconToolbar from "./InfoIconToolbar";
import selectStyleHeader from "Utils/selectStyleHeader";
import SelectComponent from "./SelectComponent";
import CompareComponent from "Components/CompareComponent";
import PolygonAdd from "./PolygonAdd";
import useAuth from "Hooks/useAuth";
import useProjects from "Hooks/useProjects";

const HeaderComponent = ({ active3d, handle3dClick, mapRef, isLayerMenuOpen }) => {
    const [registeredUserOpen, setRegisteredUserOpen] = useState(false);
    const registeredUserIconRef = useRef(null);
    const registeredUserMenuRef = useRef(null);
    const [projectsSearchOpen, setProjectsSearchOpen] = useState(false);
    const { authData } = useAuth();
    const { selectedProjectForViewOnMap } = useProjects();

    useEffect(() => {
        const handleClickOutsideOfIcon = (e) => {
            if (registeredUserIconRef.current.contains(e.target)) {
                setRegisteredUserOpen((prev) => !prev);
            }
        };

        const handleClickOutsideOfMenu = (e) => {
            if (registeredUserOpen && !registeredUserMenuRef.current.contains(e.target)) {
                setRegisteredUserOpen((prev) => !prev);
            }
        };

        document.addEventListener("click", handleClickOutsideOfIcon);
        document.addEventListener("click", handleClickOutsideOfMenu);

        return () => {
            document.removeEventListener("click", handleClickOutsideOfIcon);
            document.removeEventListener("click", handleClickOutsideOfMenu);
        };
    });

    return (
        <>
            <div className="absolute top-0 flex justify-between bg-green-100 h-16 md:h-[56px] z-[5000] w-screen font-inter flex-row items-center text-white-100">
                {/*DESKTOP HEADER*/}
                <div className="text-white-100 w-fit md:hidden pl-4">
                    <SelectComponent
                        selectOpen={setProjectsSearchOpen}
                        style={selectStyleHeader}
                        className={"mr-1.5 w-[250px] xl:w-[250px] md:mt-3 md:w-[90vw]"}
                        isLayerMenuOpen={isLayerMenuOpen}
                    />
                </div>
                <div
                    className="flex gap-x-[15px] md:hidden mr-[50px] header-tool-wrapper"
                    disabled={selectedProjectForViewOnMap?.id ? false : true}
                >
                    <CompareComponent
                        type={"desktop"}
                        active3d={active3d}
                    />
                    <PolygonAdd active3d={active3d} />
                    <MeasureComponent
                        mapRef={mapRef}
                        active3d={active3d}
                    />
                    <button
                        title="3D"
                        className={`${
                            active3d.active
                                ? "bg-white-100 button-active"
                                : active3d.available || !selectedProjectForViewOnMap?.id
                                ? "bg-green-500 hover:bg-white-100 state-hover-color"
                                : "bg-green-500 hover:bg-white-100 state-hover-color"
                        } w-[44px] h-[44px] flex justify-center items-center rounded-[50%]`}
                        onClick={handle3dClick}
                    >
                        <ThreeDIcon />
                    </button>
                    <InfoIconToolbar />
                </div>
                <div>
                    <a
                        title="Login"
                        //href="http://localhost:3000/login"
                        className="text-white-100 pr-4 cursor-pointer flex"
                        ref={registeredUserIconRef}
                    >
                        <span className="md:hidden group hover:text-green-200">
                            <span className="lg:hidden">{authData.username}</span> <FingerprintIcon />
                        </span>
                        <div className="flex items-center">
                            <div className="hidden md:block md:absolute md:right-[11px]">
                                <MobileLoginIcon />
                            </div>
                        </div>
                    </a>
                    {registeredUserOpen ? (
                        <div ref={registeredUserMenuRef}>
                            <RegisteredUserMenu />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};
export default HeaderComponent;
