import "./App.css";

// Hooks, services
import React from "react";
import { AppContextProvider } from "./Services/AppContext";
import { Routes, Route } from "react-router-dom";

// Components
import PersistLogin from "./Components/Auth/PersistLogin";
import RequireAuth from "./Components/Auth/RequireAuth";
import CookiePage from "Pages/CookiePage";

/* import Notification from "Components/Notification"; */
import MapPage from "Pages/MapPage";

/*Google Analytics*/
import ReactGA from "react-ga";
import Logout from "Components/Auth/Logout";
import LoginPage from "Pages/LoginPage";

function App() {
    const TRACKING_ID = "UA-167102395-8"; // Tracking ID for Google Analytics
    ReactGA.initialize(TRACKING_ID);
    return (
        <AppContextProvider>
            {/* Add new high level context to AppContextProvider, not as children! */}
            {/* <Notification /> */}
            <script></script>
            <Routes>
                <Route
                    path="login"
                    element={<LoginPage />}
                />
                <Route
                    path="logout"
                    element={<Logout />}
                />
                <Route
                    path="cookie"
                    element={<CookiePage />}
                ></Route>

                {/* Protected Routes */}
                <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                        <Route
                            path="/"
                            element={<MapPage />}
                        />
                    </Route>
                </Route>
            </Routes>
        </AppContextProvider>
    );
}

export default App;
