import React, { useEffect, useState } from "react";

import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
/* import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon"; */

import selectStyle from "Utils/selectStyle";
import useAxiosPrivate from "Hooks/useAxiosPrivate";
import useMap from "Hooks/useMap";
import "../Style/availableProjects.css";
import "react-datepicker/dist/react-datepicker.css";
import useProjects from "Hooks/useProjects";
import useProjectObjects from "Hooks/useProjectObjects";
import { transform } from "ol/proj";

const AvailableProjectsForLogedUser = () => {
    const [availableProjectsOpen, setAvailableProjectsOpen] = useState(true);
    const [selectedProjectByName, setSelectedProjectByName] = useState("");
    const { projects, setProjects, setSelectedProjectForViewOnMap } = useProjects();
    const { setObjectsForSelectedProject } = useProjectObjects();
    const [availableProjects, setAvailableProjects] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const { map } = useMap();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        const getProjects = async () => {
            const { data } = await axiosPrivate.get("/api/projects/");
            setProjects(data);
            setAvailableProjects(data);
        };

        getProjects();
    }, []);

    let renderProjects = availableProjects?.results
        ?.filter((project) => {
            if (!selectedProjectByName) {
                return project;
            }
            if (selectedProjectByName.value === project.name) {
                return project;
            }
        })
        .filter((project) => {
            if (!endDate && !startDate) {
                return project;
            }
            let date = moment(project.project_date);
            let end = moment(endDate);
            let start = moment(startDate);

            if (!(date.isBefore(start) || date.isAfter(end)) || date.isSame(endDate, "days")) {
                return project;
            }
        })
        .map((project) => {
            return (
                <div
                    key={project.id}
                    className="bg-projects-card-bg leading-tight bg-no-repeat bg-auto bg-center cursor-pointer h-[150px] w-[150px] rounded-[10px] border-[5px] border-gray-100 text-center"
                    onClick={() => handleOnProjectClick(project)}
                >
                    <div className="bg-white-400 flex flex-col h-full w-full justify-center items-center rounded-[10px] hover:bg-green-900 text-center overflow-hidden">
                        <h3
                            className="text-[1.5rem] max-h-[90%] text-white-100 text-ellipsis overflow-hidden break-word"
                            title={project.name}
                        >
                            {project.name}
                        </h3>

                        {/*  <p className="text-[18px] text-white-100">
                            {moment(project.project_date).format("DD.MM.YYYY.")}
                        </p> */}
                    </div>
                </div>
            );
        });

    const handleOnProjectClick = (project) => {
        let view = map.getView();

        view.setCenter(transform(project.geom.coordinates, "EPSG:4326", "EPSG:3857"));
        view.setZoom(16);
        setAvailableProjectsOpen(false);

        setSelectedProjectForViewOnMap({
            value: project,
            //label: `${project.name.toUpperCase()}, ${moment(project.project_date).format("DD.MM.YYYY.")}`,
            label: `${project.name.toUpperCase()},`,
            id: project.id,
            geom: project.geom,
        });

        // eslint-disable-next-line no-unused-vars
        const getProjectData = async () => {
            const { data } = await axiosPrivate.get("/api/habitats/objects", {
                params: {
                    project: project.id,
                },
            });

            setObjectsForSelectedProject(data);
        };
        //getProjectData();
    };

    const onDateChange = (dates) => {
        let [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <>
            {availableProjectsOpen ? (
                <div>
                    <div className="bg-projects-bg leading-tight bg-no-repeat bg-cover font-inter px-[73px] fixed top-[72px] bottom-2 left-2 right-2 z-40 bg-white-100 md:h-full md:w-full md:rounded-none md:bg-white-100 md:bottom-0 md:left-0 md:right-0 md:top-0 md:px-0 md:py-0 md:overflow-auto ">
                        <div className="relative max-w-[1100px] mt-14 mx-auto px-8 pb-8 md:p-0">
                            <div className="relative flex justify-between items-center mb-6 lg:flex-col lg:items-start md:flex-col md:items-start md:mb-6 md:pt-5 md:px-5">
                                <div className="text-[48px] text-green-100 lg:mb-4 md:mb-3 md:text-[32px]">
                                    Odaberi projekt
                                </div>
                                <div className="flex items-center xl:hidden lg:hidden md:hidden">
                                    <div className="w-[180px] h-[3px] bg-green-900"></div>
                                    <div className="w-[14px] h-[14px] rounded-[50%] bg-green-900"></div>
                                </div>
                                <div className="flex items-center lg:flex-col lg:items-start md:flex-col md:items-start">
                                    <Select
                                        styles={selectStyle}
                                        className="mr-1.5 w-[250px] xl:w-[250px] lg:w-[45vw] lg:mb-1 md:mt-3 md:w-[90vw]"
                                        options={projects?.results?.map((project) => {
                                            return {
                                                value: project.name,
                                                label: project.name,
                                            };
                                        })}
                                        type="text"
                                        placeholder="Unesi lokaciju"
                                        isSearchable
                                        isClearable
                                        onChange={setSelectedProjectByName}
                                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    />
                                    <DatePicker
                                        selected={startDate}
                                        onChange={onDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        placeholderText="Datum"
                                        selectsRange
                                        dateFormat="dd.MM.yyyy"
                                        isClearable
                                        className="md:mt-1 lg:w-[25vw] md:w-[50vw] border-[1px] rounded-3xl border-green-900 react-date-select cursor-default"
                                    />
                                </div>
                            </div>
                            <div className="md:w-[90vw] md:ml-5 md:h-[3px] md:bg-green-900 "></div>

                            {/* Grid Container for Available Projects */}
                            <div className="overflow-y-auto overflow-x-hidden justify-items-center bottom-[20px] top-[100px] scrollbar p-14 h-[65vh] bg-gray-800 grid gap-y-7 gap-x-12 grid-cols-5 rounded-[20px] xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 md:py-6 md:px-7 md:gap-2 md:h-full md:overflow-hidden md:bg-transparent">
                                {renderProjects}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default AvailableProjectsForLogedUser;
