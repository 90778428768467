import React, { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "Hooks/useAxiosPrivate";

const ProjectsContext = createContext({});

export const ProjectsProvider = ({ children }) => {
    const [projects, setProjects] = useState();
    const [selectedProjectForViewOnMap, setSelectedProjectForViewOnMap] = useState(null);
    const [classes, setClasses] = useState();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const getClasses = async () => {
            const { data } = await axiosPrivate.get("/api/habitats/classes/");
            setClasses(data);
        };

        getClasses();
    }, []);

    return (
        <ProjectsContext.Provider
            value={{
                projects,
                setProjects,
                selectedProjectForViewOnMap,
                setSelectedProjectForViewOnMap,
                classes,
            }}
        >
            {children}
        </ProjectsContext.Provider>
    );
};

export default ProjectsContext;
