const mainSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#ECFDF5" : "transparent",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#545454",
        padding: "6px 20px",
        lineHeight: "1.5",
        "&:hover": {
            backgroundColor: "#ECFDF5",
        },
        cursor: "pointer",
    }),
    menu: (provided) => ({
        ...provided,
        border: "1px solid rgba(0, 0, 0, 0.05)",
        boxShadow: "none",
        left: "-5px",
        right: "2px",
        width: "82vw",
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: "normal",
        fontSize: "14px",
        borderColor: "rgba(0, 96, 70, 0.25)",
        boxShadow: "none",
        borderRadius: "20px",
        height: "40px",
        width: "100%",
        "&:hover": {
            borderColor: "rgba(0, 96, 70, 0.25)",
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: "20px",
        whiteSpace: "nowrap",
        color: "#545454",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#545454",
        fontSize: "16px",
    }),
    input: (provided) => ({
        ...provided,
        color: "#545454",
        cursor: "text",
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#B1B1B1",
        boxShadow: "none",
        fontSize: "16px",
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
            background: "#ccdad1",
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "rgba(0, 96, 70, 0.25)",
        },
    }),
    indicatorContainer: (provided) => ({
        ...provided,
        color: "red",
    }),
};

export default mainSelectStyle;
