const mainSelectStyle = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#ECFDF5" : "transparent",
        fontWeight: "normal",
        fontSize: "14px",
        color: "#545454",
        padding: "6px 20px",
        lineHeight: "1.5",
        "&:hover": {
            backgroundColor: "#ECFDF5",
        },
        cursor: "pointer",
        "@media only screen and (max-width: 1023px) and (min-width: 767px)": {
            fontSize: "12px",
        },
    }),
    menu: (provided) => ({
        ...provided,
        border: "1px solid rgba(0, 0, 0, 0.05)",
        boxShadow: "0 4px 15px 0px rgb(0,0,0,10%);",
        marginTop: "12px"
    }),
    control: (provided) => ({
        ...provided,
        fontWeight: "normal",
        fontSize: "14px",
        border: "none",
        "&:hover": {
            borderColor: "none",
            boxShadow: "none",
        },
        boxShadow: "none",
        borderRadius: "20px",
        backgroundColor: "#FFFFFF",
        height: "40px",
        width: "330px",
        "@media only screen and (max-width: 1023px) and (min-width: 767px)": {
            fontSize: "12px",
            width: "230px",
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: "20px",
        whiteSpace: "nowrap",
        color: "#545454",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#545454",
        fontSize: "16px",
        "@media only screen and (max-width: 1023px) and (min-width: 767px)": {
            fontSize: "14px",
        },
    }),
    input: (provided) => ({
        ...provided,
        color: "#545454",
        cursor: "text",
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#B1B1B1",
        boxShadow: "none",
        fontSize: "14px",
        "@media only screen and (max-width: 1023px) and (min-width: 767px)": {
            fontSize: "12px",
        },
    }),
    menuList: (base) => ({
        ...base,
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
            background: "#ccdad1",
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "rgba(0, 96, 70, 0.25)",
        },
    }),
    indicatorContainer: () => ({
        display: "none",
    }),
};

export default mainSelectStyle;
