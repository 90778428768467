import useMap from "Hooks/useMap";
import React, { useEffect, useState, useRef } from "react";

import OLCesium from "olcs/OLCesium.js";
import useProjects from "Hooks/useProjects";

const Olcs3D = ({ active3d, setActive3d }) => {
    const { map } = useMap();
    const { selectedProjectForViewOnMap } = useProjects();

    const Cesium = window.Cesium;

    const [olcs3d, setOlcs3d] = useState();

    // Measure
    const cesiumMeasure = useRef();

    const [measuredDistances, setMeasuredDistances] = useState();

    const closeOnResize = (e) => {
        if (e.target.innerWidth < 768) {
            setActive3d((prev) => {
                return { ...prev, active: false };
            });
        }
    };

    // Add 3D after map was initialized
    useEffect(() => {
        if (map) {
            const ol3d = new OLCesium({
                map: map,
                time() {
                    return window.Cesium.JulianDate.now();
                },
            });
            setOlcs3d(ol3d);
            const scene = ol3d.getCesiumScene();

            // eslint-disable-next-line no-undef
            window.Cesium.Ion.defaultAccessToken = process.env.REACT_APP_TERRAIN_TOKEN;

            let terrainProvider = new Cesium.CesiumTerrainProvider({
                url: window.Cesium.IonResource.fromAssetId(1),
                // url: "http://localhost:8080/tilesets/terrain/",
            });

            scene.terrainProvider = terrainProvider;

            addHandler(ol3d);
            window.addEventListener("resize", closeOnResize);
        }

        return () => {
            // Remove from map on unmount ?
            window.removeEventListener("resize", closeOnResize);
        };
    }, [map]);

    // Toggle 3D functionality
    useEffect(() => {
        if (olcs3d) {
            if (olcs3d.getEnabled()) {
                let layers = map.getAllLayers();
                [layers] = layers.filter((layer) => layer.get("title") === "DOF");
                let layerSource = layers.getSource();
                layerSource.setUrl("https://geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms");
                olcs3d.setEnabled(false);
            } else {
                let layers = map.getAllLayers();
                [layers] = layers.filter((layer) => layer.get("title") === "DOF");
                let layerSource = layers.getSource();
                layerSource.setUrl(
                    "https://wms-proxy.listlabs.net/geoportal.dgu.hr/services/inspire/orthophoto_2014-2016/wms"
                );
                olcs3d.setEnabled(true);
            }
        }

        setMeasuredDistances();
    }, [active3d.active]);

    // Load active project and handle change
    useEffect(() => {
        let tileSet3DSource;

        if (!olcs3d) return;

        // HARDCODED - be careful what you do here!
        const scene = olcs3d.getCesiumScene();

        const tileSetId = selectedProjectForViewOnMap?.id === 25164 ? 2508010 : 2509391;

        const testTileSet = new Cesium.Cesium3DTileset({
            url: window.Cesium.IonResource.fromAssetId(tileSetId),
        });

        if (active3d.available === false) {
            const tileSetHardCoded = scene.primitives.add(testTileSet);
            tileSetHardCoded.pointCloudShading.maximumAttenuation = undefined;
            tileSetHardCoded.pointCloudShading.baseResolution = undefined;
            tileSetHardCoded.pointCloudShading.geometricErrorScale = 1;
            tileSetHardCoded.pointCloudShading.attenuation = true;
            tileSetHardCoded.pointCloudShading.eyeDomeLighting = true;
        }

        if (selectedProjectForViewOnMap?.value.cesium_tileset_url) {
            setActive3d((prev) => {
                return { ...prev, available: true };
            });

            //const scene = olcs3d.getCesiumScene();

            tileSet3DSource = new Cesium.Cesium3DTileset({
                url: selectedProjectForViewOnMap.value.cesium_tileset_url,
                maximumScreenSpaceError: 16,
                skipLevelOfDetail: true,
                baseScreenSpaceError: 1024,
            });

            let tileset = scene.primitives.add(tileSet3DSource);
            tileset.pointCloudShading.maximumAttenuation = undefined;
            tileset.pointCloudShading.baseResolution = undefined;
            tileset.pointCloudShading.geometricErrorScale = 1;
            tileset.pointCloudShading.attenuation = true;
            tileset.pointCloudShading.eyeDomeLighting = true;
        } else if (active3d.available) {
            // Unset new project as 3d enabled

            setActive3d((prev) => {
                return { ...prev, available: false };
            });
        }

        return () => {
            // Destroy on dismount and exit 3D - TODO Check if this works!
            if (tileSet3DSource) {
                const scene = olcs3d?.getCesiumScene();
                scene?.primitives.remove(tileSet3DSource);

                try {
                    scene?.primitives.remove(testTileSet);
                } catch (error) {
                    console.log("Unable to remove primitive");
                }

                // Exit 3D
                setActive3d((prev) => {
                    return { ...prev, active: false };
                });
            }
            setMeasuredDistances();
        };
    }, [selectedProjectForViewOnMap]);

    // 3D measure - TODO rewrite this to separate component and enable header control

    const addHandler = (viewer) => {
        let scene = viewer.getCesiumScene();

        let points = scene.primitives.add(new Cesium.PointPrimitiveCollection());
        let point1, point2;
        let point1GeoPosition, point2GeoPosition, point3GeoPosition;
        let polylines = scene.primitives.add(new Cesium.PolylineCollection());
        let polyline1, polyline2, polyline3;
        let distanceLabel, verticalLabel, horizontalLabel;
        let LINEPOINTCOLOR = Cesium.Color.fromCssColorString("#ffcc33");
        var ellipsoid = Cesium.Ellipsoid.WGS84;
        var geodesic = new Cesium.EllipsoidGeodesic();

        var label = {
            font: "12px sans-serif",
            showBackground: true,
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.CENTER,
            pixelOffset: new Cesium.Cartesian2(0, 0),
            eyeOffset: new Cesium.Cartesian3(0, 0, -50),
            fillColor: Cesium.Color.BLACK,
            backgroundColor: Cesium.Color.fromCssColorString("#ffcc33CC"),
        };

        function addDistanceLabel(point1, point2, height) {
            point1.cartographic = ellipsoid.cartesianToCartographic(point1.position);
            point2.cartographic = ellipsoid.cartesianToCartographic(point2.position);
            point1.longitude = parseFloat(Cesium.Math.toDegrees(point1.position.x));
            point1.latitude = parseFloat(Cesium.Math.toDegrees(point1.position.y));
            point2.longitude = parseFloat(Cesium.Math.toDegrees(point2.position.x));
            point2.latitude = parseFloat(Cesium.Math.toDegrees(point2.position.y));

            // Three distances
            const horizontalDistance = getHorizontalDistanceString(point1, point2);
            const trueDistance = getDistanceString(point1, point2);
            const verticalDistance = getVerticalDistanceString();
            setMeasuredDistances({
                verticalDistance: verticalDistance,
                horizontalDistance: horizontalDistance,
                trueDistance: trueDistance,
            });

            // Labels
            label.text = horizontalDistance;
            horizontalLabel = viewer.getDataSourceDisplay().defaultDataSource.entities.add({
                position: getMidpoint(point1, point2, point1GeoPosition.height),
                label: label,
            });
            label.text = trueDistance;
            distanceLabel = viewer.getDataSourceDisplay().defaultDataSource.entities.add({
                position: getMidpoint(point1, point2, height),
                label: label,
            });
            label.text = verticalDistance;
            verticalLabel = viewer.getDataSourceDisplay().defaultDataSource.entities.add({
                position: getMidpoint(point2, point2, height),
                label: label,
            });
        }

        function getHorizontalDistanceString(point1, point2) {
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var meters = geodesic.surfaceDistance.toFixed(2);
            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters + " м";
        }

        function getVerticalDistanceString() {
            var heights = [point1GeoPosition.height, point2GeoPosition.height];
            var meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters.toFixed(2) + " м";
        }

        function getDistanceString(point1, point2) {
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
            var heights = [point1GeoPosition.height, point2GeoPosition.height];
            var verticalMeters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
            var meters = Math.pow(Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2), 0.5);

            if (meters >= 1000) {
                return (meters / 1000).toFixed(1) + " км";
            }
            return meters.toFixed(2) + " м";
        }

        function getMidpoint(point1, point2, height) {
            var scratch = new Cesium.Cartographic();
            geodesic.setEndPoints(point1.cartographic, point2.cartographic);
            var midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch);
            return Cesium.Cartesian3.fromRadians(midpointCartographic.longitude, midpointCartographic.latitude, height);
        }

        let handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);

        const inputAction = (click) => {
            if (scene.mode !== Cesium.SceneMode.MORPHING) {
                var pickedObject = scene.pick(click.position);
                if (scene.pickPositionSupported && Cesium.defined(pickedObject)) {
                    var cartesian = scene.pickPosition(click.position);
                    // console.log(cartesian);
                    if (Cesium.defined(cartesian)) {
                        if (points.length === 2) {
                            points.removeAll();
                            polylines.removeAll();
                            viewer.getDataSourceDisplay().defaultDataSource.entities.remove(distanceLabel);
                            viewer.getDataSourceDisplay().defaultDataSource.entities.remove(horizontalLabel);
                            viewer.getDataSourceDisplay().defaultDataSource.entities.remove(verticalLabel);
                            setMeasuredDistances();
                        }
                        //add first point
                        if (points.length === 0) {
                            point1 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR,
                            });
                            setMeasuredDistances();
                        } //add second point and lines
                        else if (points.length === 1) {
                            point2 = points.add({
                                position: new Cesium.Cartesian3(cartesian.x, cartesian.y, cartesian.z),
                                color: LINEPOINTCOLOR,
                            });
                            point1GeoPosition = Cesium.Cartographic.fromCartesian(point1.position);
                            point2GeoPosition = Cesium.Cartographic.fromCartesian(point2.position);
                            // eslint-disable-next-line no-undef
                            point3GeoPosition = Cesium.Cartographic.fromCartesian(
                                new Cesium.Cartesian3(point2.position.x, point2.position.y, point1.position.z)
                            );

                            var pl1Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point1GeoPosition.longitude,
                                    point1GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point2GeoPosition.height
                                ),
                            ];
                            var pl2Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point2GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                            ];
                            var pl3Positions = [
                                new Cesium.Cartesian3.fromRadians(
                                    point1GeoPosition.longitude,
                                    point1GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                                new Cesium.Cartesian3.fromRadians(
                                    point2GeoPosition.longitude,
                                    point2GeoPosition.latitude,
                                    point1GeoPosition.height
                                ),
                            ];

                            polyline1 = polylines.add({
                                show: true,
                                positions: pl1Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "Color",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            polyline2 = polylines.add({
                                show: true,
                                positions: pl2Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "PolylineDash",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            polyline3 = polylines.add({
                                show: true,
                                positions: pl3Positions,
                                width: 1,
                                material: new Cesium.Material({
                                    fabric: {
                                        type: "PolylineDash",
                                        uniforms: {
                                            color: LINEPOINTCOLOR,
                                        },
                                    },
                                }),
                            });
                            var labelZ;
                            if (point2GeoPosition.height >= point1GeoPosition.height) {
                                labelZ =
                                    point1GeoPosition.height +
                                    (point2GeoPosition.height - point1GeoPosition.height) / 2.0;
                            } else {
                                labelZ =
                                    point2GeoPosition.height +
                                    (point1GeoPosition.height - point2GeoPosition.height) / 2.0;
                            }

                            addDistanceLabel(point1, point2, labelZ);
                        }
                    }
                }
            }
        };

        handler.setInputAction(inputAction, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    };

    // 3D measure

    return (
        <>
            {active3d && (
                <div
                    className="bg-white-100 absolute top-[72px] right-2 z-[5000] p-4 rounded-xl h-fit w-fit text-green-500"
                    hidden={!measuredDistances}
                >
                    <p>
                        Udaljenost: <span className="text-gray-200">{measuredDistances?.trueDistance}</span>
                    </p>
                    <p>
                        Horizontalna udaljenost:{" "}
                        <span className="text-gray-200">{measuredDistances?.horizontalDistance}</span>
                    </p>
                    <p>
                        Vertikalna udaljenost:{" "}
                        <span className="text-gray-200">{measuredDistances?.verticalDistance}</span>
                    </p>
                </div>
            )}
        </>
    );
};

export default Olcs3D;
