import React, { useEffect, useState } from "react";
import useMap from "Hooks/useMap";
import { CompareIcon } from "Media/Icons/HeaderComponentIcons";
import OlSideBySideControl from "Utils/side-by-side";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

const CompareComponent = ({ type, active3d }) => {
    const { map } = useMap();

    const [compareOpen, setCompareOpen] = useState(false);

    const handleOpen = () => {
        setCompareOpen(!compareOpen);
    };

    const closeOnResize = (e) => {
        if (type === "desktop") {
            e.target.innerWidth < 768 ? setCompareOpen(false) : "";
        } else {
            e.target.innerWidth >= 768 ? setCompareOpen(false) : "";
        }
    };

    useEffect(() => {
        let sbs, layer1;
        if (compareOpen) {
            // eslint-disable-next-line no-undef
            let accesToken = process.env.REACT_APP_SATELLITE_MAP_TOKEN;
            let address1 = "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.jpg90?access_token=";

            layer1 = new TileLayer({
                source: new XYZ({
                    url: address1 + accesToken,
                }),
            });

            map.addLayer(layer1);

            sbs = new OlSideBySideControl();

            map.addControl(sbs);

            sbs.open();
            sbs.setRightLayer(layer1);

            window.addEventListener("resize", closeOnResize);
        }

        return () => {
            window.removeEventListener("resize", closeOnResize);

            if (map && sbs) {
                sbs.remove();
                map.removeControl(sbs);
                //map.addLayer(currentBase);
            }
        };
    }, [compareOpen]);

    return (
        <>
            {/* Desktop button */}
            <button
                title="Usporedi"
                className={`${
                    compareOpen ? "bg-white-100 text-green-500" : "bg-green-500"
                } group w-[44px] md:bg-transparent h-[44px] flex justify-center items-center rounded-[50%] hover:bg-white-100 md:hidden disabled:hover:bg-green-500 ${
                    active3d?.active ? "button-disabled" : ""
                }`}
                onClick={handleOpen}
                disabled={active3d?.active}
            >
                <CompareIcon />
            </button>

            {/* Mobile button */}

            <button
                title="Usporedi"
                className={`${
                    compareOpen ? "md:bg-green-500" : "md:bg-transparent"
                } hidden md:flex md:w-[44px] md:h-[44px] md:justify-center md:items-center md:rounded-lg`}
                onClick={handleOpen}
            >
                <CompareIcon isOpen={compareOpen} />
            </button>

            {compareOpen && (
                <div className="font-inter fixed -translate-x-1/2 left-1/2 w-1/2 md:w-auto md:max-w-xs top-16 md:top-[55px] rounded-b-md flex flex-row justify-around items-center text-gray-400 bg-white-100 h-[50px]">
                    <p className="md:text-sm">Base Map</p>
                    <p className="md:text-sm text-green-100 font-semibold md:w-1/4">Compare imagery</p>
                    <p className="md:text-sm">Satellite</p>
                </div>
            )}
        </>
    );
};

export default CompareComponent;
