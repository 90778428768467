import useAuth from "./useAuth";
import useAxiosPrivate from "Hooks/useAxiosPrivate";

const useLogout = () => {
    const { setAuthData, setAuth, setPersist } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const logout = async () => {
        setAuth({});
        try {
            const response = await axiosPrivate.post("/api/user/token/logout/", {});
            if (response) {
                setAuthData({});
                setAuth({});
                setPersist(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return logout;
};

export default useLogout;
